import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {msg_icon,pass_icon,login_img, landingLogo } from '../../../utilities/images';
import { userLogin } from '../../actions/user';
import { loginSuccess } from '../../../redux/actions/auth';
import LoginNavbar from '../../components/LoginNavbar';

const Login = (props) => {
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
       
    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    //validation form on email password 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
            }
        }

        if (!formData.password) {
            formIsValid = false;
            errors = { ...errors, password: "Please enter your password." };
        }

        setErrorMessages(errors);
        return formIsValid;
    }

    //form submit on login 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.email) {
            toast.error("Please enter email");
            return;
        }
        else if (formData.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email)) {
            toast.error("Please enter valid email id");
            return;
        }
        else if (!formData.password) {
            toast.error("Please enter password");
            return;
        }

        if (validateForm()) {
            setLoading(true);

            let params = {
                email: formData.email.trim(),
                password: formData.password,
                role:'admin'
            }
            props.userLogin(params, res => {
                setLoading(false);
                if (res.status == true) {
                    let userData = {
                        userProfile: {
                            email: res.data.user_profile.email,
                            is_active: res.data.user_profile.isActive,
                            id: res.data.user_profile.id,
                            userProfileFull: res.data.user_profile
                        },
                        token: res.data.token
                    }
                    props.onLoginSuccess(userData);
                    toast.success('Logged in successfully')
                    history.push('/');
                }
                else {
                     toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }

    };

    useEffect(() => {
        document.title = "Login";
    }, []);

    return (
        <>
        {/* <LoginNavbar /> */}
            <section className='commonLogin'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className="col-lg-12">
                        <a className="navbar-brand d-flex justify-content-center"><img src={landingLogo} className="loginLogo" /></a>
                            <div className="loginForm">
                                <h4 className="heading">Login</h4>
                                <p className="text">Welcome! Login to your account.</p>
                                <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal">
                                    <div className="mb-4 form-group col-md-12">
                                        <label className="form-label">Email </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder='Email Address'
                                            value={formData.email}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={msg_icon} className="icon_" alt="message" />
                                    </div>
                                    <div className="mb-4 form-group col-md-12">
                                        <label className="form-label">Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="Password"
                                            name="password"
                                            type={passwordShown ? "text" : "password"}
                                            value={formData.password}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={pass_icon} className="icon_" alt="password" />
                                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                                            <span className={passwordShown ? "hide-icon togglePassword" : "show-icon togglePassword"} id=""></span>
                                        </span>
                                    </div>
                                    <div className='row align-items-center mb-3'>
                                        <div className='col-6 col-sm-6 col-md-6'>
                                            <div className='form-group'>
                                                <div className='d-flex align-items-center'>
                                                    <span className='innersubtext'>Remember</span>
                                                    <label className="switch" for='carePoint'>
                                                        <input type="checkbox" name="carePoint" id="carePoint" value="1" />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 col-sm-6 col-md-6'>
                                            <div className="form-group">
                                                <p className="forgot mb-0" onClick={() => { history.push('/forgotPassword') }}>Forgot Password?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-grid gap-2 col-6 mx-auto'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Login</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    userLogin: bindActionCreators(userLogin, dispatch),
    onLoginSuccess: payload => dispatch(loginSuccess(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Login);