import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import landingLogo from "../../public/images/logo.svg";
import userIcon from "../../public/images/user.svg";
import { editIcon, fade_PassIcon, logout_icon, mail_icon_fade, msg_icon, notification, user_profile, user_Img } from '../../../utilities/images';
import { getUserDetails, userLogOut } from '../../actions/user';
import CustomModal from "../../components/shared/CustomModal"
import ChangePassword from '../../containers/auth/changePassword';
import UserProfileModal from '../../containers/auth/userProfileModal';
import NotificationModal from './NotificationModal';
import { openNav } from '../../../redux/actions/nav';

const Header = (props) => {
    
    const history = useHistory();
    const openActions = () => {
    document.body.setAttribute("style", "overflow:hidden");
    }
    const closeActions = () => {
    document.body.setAttribute("style", "overflow:auto");
    }
    const scrollHide = (id) =>
    {
        setModalDetail({
            show: true,
            title: "Notifications",
            flag: "notifications",
            });
            setKey(Math.random());
            // document.body.setAttribute("style", "overflow:hidden")
    }
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
      show: false,
      title: "",
      flag: "",
    });
    

    const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setKey(Math.random());
        // document.body.setAttribute("style", "overflow:auto")
      };
    

    //user logout 
    const userLogout = () => {
        let params = {
        token: props.auth.token
        }
        props.userLogout(params, res => {
        if (res.status) {
            toast.success(res.message);
            history.push('/login');
        }
        else {
            toast.error(res.message);
        }
        },
        err => {
            toast.error(err.message);
        })
    }

    
    return (
        <>
            {/* START header  */}
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light">
                        <a className="navbar-brand" onClick={() => {history.push('/')}} ><img src={landingLogo} className="loginLogo" /></a>
                        {/* <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                        </li> */}
                        <ul className="topNavbar_">
                            <li className="nav-item">
                                <button className="innersubtext border-0 bg-transparent"
                                    onClick={() => scrollHide()}
                                >
                                    <img src={notification}  className='me-2 bellIcon_' />
                                    <span className='navTxt__'>Notifications</span>
                                </button>
                            </li>
                            <li className="nav-item userProfile dropdown">
                                <Link to='' className='navLink d-flex align-items-center dropdown-toggle' id='dropdownMenuProfile' data-bs-toggle="dropdown" aria-expanded='true'>
                                    <div className='userDetails'>
                                        <h6 className='userName'>{props.auth.userProfile?.userProfileFull?.userInfo?.fullName}</h6>
                                        <p className='smallTxt'>Admin</p>
                                    </div>
                                    <img className="user" src={props.auth.userProfile?.userProfileFull?.userInfo?.profilePhoto ??  user_Img} alt='admin'/>

                                </Link>
                                {/* Start User Profile Dropdown */}
                                <div className='dropdown-menu profileDropdown' aria-labelledby="dropdownMenuProfile">
                                    <div className='userProfileArea'>
                                        <div className='userProfileTxt'>
                                            <img src={props.auth.userProfile?.userProfileFull?.userInfo?.profilePhoto ?? user_Img} className="user" alt=''/>
                                            <div className="userDetails"><h6 className="userName">{props.auth.userProfile?.userProfileFull?.userInfo?.fullName}</h6><p className="smallTxt">Admin</p></div>
                                        </div>
                                        <Link to="/#" className='editDetails'>
                                            <img src={editIcon} alt=''
                                            onClick={() => {
                                                setModalDetail({
                                                  show: true,
                                                  title: "Edit Profile",
                                                  flag: "userProfile",
                                                });
                                                setKey(Math.random());
                                            }} />
                                        </Link>
                                    </div>
                                    <hr />
                                    <ul className='userProfileMenus_'>
                                        <li className='userProfileItems_'>
                                            <Link to="/#" className='userProfileLinks_'>
                                                <img src={mail_icon_fade} alt=''/>
                                                <span>{props.auth.userProfile?.userProfileFull?.email}</span>
                                            </Link>
                                        </li>
                                        <li className='userProfileItems_'   
                                        onClick={() => {
                                                setModalDetail({
                                                    show: true,
                                                    title: "Change Password",

                                                    flag: "changePassword",
                                                });
                                                setKey(Math.random())}}
                                        >
                                            <Link  to="/#" className='userProfileLinks_'>
                                                <img src={fade_PassIcon} alt=''/>
                                                <span><u>Change Password</u></span>
                                            </Link>
                                        </li>
                                        <li className='userProfileItems_'  onClick={() => { userLogout() }}>
                                            <Link to="/#" className='logout'>
                                                <img src={logout_icon} />
                                                <span>Log Out</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                {/* End User Profile Dropdown */}
                            </li>
                        </ul>
                        <span  onClick={()=>{props.openNav();
                        openActions()
                        }} className="openbtn2">
                            <i className="fa fa-bars" aria-hidden="true"></i>
                        </span>
                    </nav>
                </div>
            </header>

            {/* END header  */}

            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                title={modalDetail.title}
                ids={modalDetail.flag == "changePassword" ?  'changePassword' :  modalDetail.flag == 'userProfile' ? 'userProfile' : modalDetail.flag == 'notifications' ? 'notifications' : ''}
                child={
                modalDetail.flag == "changePassword" ? (
                    <ChangePassword closeModal={() => handleOnCloseModal()} />
                ) :   
                modalDetail.flag == 'userProfile' ? 
                    <UserProfileModal
                       closeModal={() => handleOnCloseModal()}
                    />
                :
                modalDetail.flag == 'notifications' ?
                    <NotificationModal
                        closeModal={() => handleOnCloseModal()}
                    />
                :   
                null
                }
                size="lg"
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    userLogout: bindActionCreators(userLogOut, dispatch),
    openNav: () => dispatch(openNav()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Header);