import {React, useState} from 'react';
import CustomModal from "../../components/shared/CustomModal";
import {
    dog_img,
    calander_icon,
    dots_icon,
    plus,
    delete_icon_black,
    pen_icon_solo,
    mail_icon_fade,
  
  } from "../../../utilities/images";
    import { Link, useHistory } from "react-router-dom";
    import AddTrainer from './add';
    import EditTrainer from './edit';
    import DeleteTrainer from './delete';
    const Trainers = () => {
    document.title = "trainers";
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
   
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
      });
    
      const handleOnCloseModal = () => {
        setModalDetail({
          show: false,
          title: "",
          flag: "",
        });
        setKey(Math.random());
      };
  return (
    <>
     
    <section className='clients'>
    <div className="container d-flex">
        <div className='content_areas'>
            <div className="row ">
               <div className='pageHeadingContainer'>
                    <div className='pageHeading'>
                        <h4 className="horizontal_heading_">
                        Trainers
                        </h4>
                        <span className="text bussiness">12 Total Trainers</span>
                    </div>
                    <div className='headerSerchBar'>
                        <div className="input-group">
                            <button className="input-group-text" type="button" id="button-addon1">
                                <i className="las la-search serchICON"></i>
                                </button>
                                <input className="form-control search__input" placeholder="Search" type="search"/>
                        </div>
                    </div>
                
               <div >
                <button className='newTrannerBtn'
                onClick={() => {
                    setModalDetail({
                      show: true,
                      title: "Add New Trainer",
                      flag: "AddTrainer",
                    });
                    setKey(Math.random());
                  }}
                > <img src={plus}/>&nbsp; &nbsp; New Trainer</button>
               </div>
            </div>  
           
            </div>  
            <div className='row mt-4'>
                <div className='col-md-12'>
                    <div className='kennelCard mb-2'>
                        <div className='kennelHeading'>
                            {/* <div className='series'> <h6>#01</h6></div> */}
                            <div className='requestBotCard'>
                                <div>
                                    <div className='requestBotImgCont'>
                                        <img src={dog_img}/>
                                        <div className='innerBot'>
                                            <h6 className='mb-0'>Teresa Mitchell</h6>
                                            {/* <span className='botSpan1'>Beagle</span> 
                                            <span className='botSpan'>Male 1.5Y</span>  */}
                                        </div>  
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="profileEmail">
                            <img src={mail_icon_fade} />
                            <span className="text">kewin885@gmail.com</span>
                        </div>
                        <div className="profileEmail">
                        <img src={calander_icon}/>&nbsp; <span>Added on 29 Sep, 2022</span>
                        </div>
                        <div className='kannelFoot'>
                            <div className='deleteIcon'>
                                <img src={dots_icon} className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"/>
                                <ul className="dropdown-menu view_dropdown" aria-labelledby="dropdownMenuButton1">
                                    <li onClick={() => {
                                        setModalDetail({
                                        show: true,
                                        title: "Edit Trainer Details",
                                        flag: "EditTrainer",
                                        });
                                        setKey(Math.random());
                                    }}><img src={pen_icon_solo}/> &nbsp; Edit Details</li>
                                    <li 
                                    onClick={() => {
                                        setModalDetail({
                                        show: true,
                                        title: "Delete Trainer",
                                        flag: "DeleteTrainer",
                                        });
                                        setKey(Math.random());
                                    }}> <img src={delete_icon_black}/> &nbsp; Delete Trainer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                       
        </div>                                                     
    </div>   
    </section>
    <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        ids={modalDetail.flag == "AddTrainer" ?  'AddTrainer' : modalDetail.flag == "EditTrainer" ?  'EditTrainer' : modalDetail.flag == "DeleteTrainer" ?  'DeleteTrainer' : ''}
        child={
          modalDetail.flag == "AddTrainer" ? (
            <AddTrainer closeModal={() => handleOnCloseModal()} />
          ) : modalDetail.flag == 'EditTrainer' ? 
          <EditTrainer
          closeModal={() => handleOnCloseModal()}
          />
           : modalDetail.flag == 'DeleteTrainer' ? 
          <DeleteTrainer
          closeModal={() => handleOnCloseModal()}
          />
          : ""
        }
        size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default Trainers