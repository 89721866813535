import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { user_Img, user_profile } from '../../../utilities/images';
import { getReportUsers } from '../../actions/user/bussinessOwner';
import { toast } from "react-toastify";
import moment from 'moment-timezone';

const ReportUser = (props) => {
  document.title = "reportUser";
  const [reportUser, setReportUser] = useState([]);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    getReportUser();
  }, []);

  const getReportUser = () => {
    const params = {
      token: props.auth.token
    }
    setLoading(true);
    props.getReportUsers(params, resp => {
      setLoading(false);
      if (resp.status == true) {
        setReportUser(resp.data)
      }
      else {
        setReportUser([])
        toast.error('Something went wrong');
      }
    },
      err => {
        setLoading(false);
        setReportUser([])
        toast.error('Sowmthing went wrong');
      }
    )
  }


  return (
    <div className='reportUser'>
      <div className='stickyHead'>
        <div className='row align-items-center'>
          <div className='col-6 col-md-6'>
            <h6 className="horizontal_heading_">Reported Users</h6>
          </div>
        </div>
      </div>
      {loading ?
        <div className="col-12 text-center p-5 mt-5 mb-5">
          <span className="spinner-border spinner-border-sm"></span>
        </div>
        : 
        <>
          {reportUser.length > 0 ?
            <>
              {reportUser.map((item, index) => {
                return (
                  <div className='reportUserContainer' key={index}>
                    <div className='row align-items-center'>
                      <div className='col-sm-6 col-md-6'>
                        <div className='requestBotImgCont'>
                          <img src={item?.userPhoto_reportBy ? item?.userPhoto_reportBy : user_Img} className='userImg' alt="UserImg" />
                          <div className="innerBot">
                            <h6 className="mb-0">{item?.userName_reportBy}</h6>
                            <span className="botSpan1 border-0">{item?.userRole_reportBy}</span>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 text-end'>
                        <div className='reportBotEnd'>
                          <span className='innersubTxt me-3'>Reported :</span>
                          <div className='requestBotImgCont'>
                            <img src={item?.userPhoto_reportTo ? item?.userPhoto_reportTo : user_Img} className='userImg' alt="UserImg" />
                            <div className="innerBot">
                              <h6 className="mb-0">{item?.userName_reportTo}</h6>
                              <span className="botSpan1 border-0">{item?.userRole_reportTo}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-12 mt-3 mb-3'>
                        <p className='innersubtext'>
                          {item?.description}
                        </p>
                      </div>
                      <div className='col-md-6'>
                        <span className='innersubTxt'>Reported :</span>
                        <span className='innersubtext'>{moment(item.createdAt).format("DD MMM, YYYY LT")}</span>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
            :
            <></>
          }
        </>
      }
      {/* <div className='reportUserContainer'>
          <div className='row align-items-center'>
            <div className='col-sm-6 col-md-6'>
              <div className='requestBotImgCont'>
                  <img src={user_profile} className='' alt="UserImg" />
                  <div className="innerBot">
                    <h6 className="mb-0">John Smith</h6>
                    <span className="botSpan1 border-0">Business Owner</span>
                  </div>
              </div>  
            </div>
            <div className='col-sm-6 col-md-6 text-end'>
              <div className='reportBotEnd'>
                <span className='innersubTxt me-3'>Reported :</span>
                <div className='requestBotImgCont'>
                    <img src={user_profile} className='' alt="UserImg" />
                    <div className="innerBot">
                      <h6 className="mb-0">Kewin William</h6>
                      <span className="botSpan1 border-0">Dog Owner</span>
                    </div>
                </div>  
              </div>
            </div>
            <div className='col-md-12 mt-3 mb-3'>
              <p className='innersubtext'>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
            <div className='col-md-6'>
              <span className='innersubTxt'>Reported :</span>
              <span className='innersubtext'>29 Dec 2022, 10:45 am</span>
            </div>
          </div>
        </div>
        <div className='reportUserContainer'>
          <div className='row align-items-center'>
            <div className='col-sm-6 col-md-6'>
              <div className='requestBotImgCont'>
                  <img src={user_profile} className='' alt="UserImg" />
                  <div className="innerBot">
                    <h6 className="mb-0">John Smith</h6>
                    <span className="botSpan1 border-0">Business Owner</span>
                  </div>
              </div>  
            </div>
            <div className='col-sm-6 col-md-6 text-end'>
              <div className='reportBotEnd'>
                <span className='innersubTxt me-3'>Reported :</span>
                <div className='requestBotImgCont'>
                    <img src={user_profile} className='' alt="UserImg" />
                    <div className="innerBot">
                      <h6 className="mb-0">Kewin William</h6>
                      <span className="botSpan1 border-0">Dog Owner</span>
                    </div>
                </div>  
              </div>
            </div>
            <div className='col-md-12'>
              <p className='innersubtext'>
                 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
              </p>
            </div>
            <div className='col-md-6'>
              <span className='innersubTxt'>Reported :</span>
              <span className='innersubtext'>29 Dec 2022, 10:45 am</span>
            </div>
          </div>
        </div> */}
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  getReportUsers: bindActionCreators(getReportUsers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportUser);

