import React from 'react'
import { dog_img } from '../../../utilities/images'

const NotificationModal = () => {
  return (
    <>
        <div className='notificationModal__ mb-2'>
            <div className="chat_container">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Eladio Reichert</h6>
                        <span className='botSpan1 border-end-0'>Trainer</span> 
                        {/* <span className='botSpan'>Male 1.5Y</span>  */}
                    </div>  
                </div>
                <div className="chat_text_right">
                    <p className="text">Just Now</p>
                </div>
            </div>
            <p className='notificationDes mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div className='notificationModal__ mb-2'>
            <div className="chat_container">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Eladio Reichert</h6>
                        <span className='botSpan1 border-end-0'>Trainer</span> 
                        {/* <span className='botSpan'>Male 1.5Y</span>  */}
                    </div>  
                </div>
                <div className="chat_text_right">
                    <p className="text">Just Now</p>
                </div>
            </div>
            <p className='notificationDes mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div className='notificationModal__ mb-2'>
            <div className="chat_container">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Eladio Reichert</h6>
                        <span className='botSpan1 border-end-0'>Trainer</span> 
                        {/* <span className='botSpan'>Male 1.5Y</span>  */}
                    </div>  
                </div>
                <div className="chat_text_right">
                    <p className="text">Just Now</p>
                </div>
            </div>
            <p className='notificationDes mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div className='notificationModal__ mb-2'>
            <div className="chat_container">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Eladio Reichert</h6>
                        <span className='botSpan1 border-end-0'>Trainer</span> 
                        {/* <span className='botSpan'>Male 1.5Y</span>  */}
                    </div>  
                </div>
                <div className="chat_text_right">
                    <p className="text">Just Now</p>
                </div>
            </div>
            <p className='notificationDes mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
        <div className='notificationModal__ mb-2'>
            <div className="chat_container">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Eladio Reichert</h6>
                        <span className='botSpan1 border-end-0'>Trainer</span> 
                        {/* <span className='botSpan'>Male 1.5Y</span>  */}
                    </div>  
                </div>
                <div className="chat_text_right">
                    <p className="text">Just Now</p>
                </div>
            </div>
            <p className='notificationDes mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>
    </>
  )
}

export default NotificationModal