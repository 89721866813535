import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { msg_icon, pass_icon } from '../../../utilities/images';
import { changePassword } from '../../actions/settings';
import { toast } from "react-toastify";

const ChangePassword = (props) => {
  
    const [isLoading, setIsLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownNewPassword, setPasswordShownNewPassword] = useState(false);
    const [passwordShownConfirmPassword, setPasswordShownConfirmPassword] = useState(false);
    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState({
        oldpassword: "",
        newpassword: "",
        confirmpassword: ""
    });

    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }


    //password field show or hide
    const togglePasswordVisiblityoldpassword = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    //password field show or hide
    const togglePasswordVisiblitynewpassword = () => {
        setPasswordShownNewPassword(passwordShownNewPassword ? false : true);
    };

    //password field show or hide
    const togglePasswordVisiblityconfirmpassword = () => {
        setPasswordShownConfirmPassword(passwordShownConfirmPassword ? false : true);
        
    };


    //on submit change password 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.oldpassword) {
          toast.error("Please enter old password");
          return;
        }
    
        else if (!formData.newpassword) {
          toast.error("Please enter new password");
          return;
        }
        else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(formData.newpassword)) {
          // Regex with small and capital letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
          // Regex with only small letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
          toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
          return;
        }
        else if (!formData.confirmpassword) {
          toast.error("Please enter confirm Password");
          return;
        }
        else if (formData.confirmpassword !== formData.newpassword) {
          toast.error("Password and confirm password doesn't match ");
          return;
        }
    
        let params = {
            old_password: formData.oldpassword,
            new_password: formData.newpassword,
            confirm_password: formData.confirmpassword,
          token: props.auth.token
        }
    
        setIsLoading(true);
        props.changePassword(params, res => {
          setIsLoading(false);
          if (res.status) {
            toast.success(res.message);
            props.closeModal();
          }
          else {
            toast.error(res.message);
          }
        },
          err => {
            setIsLoading(false);
            toast.error(err.message);
          });
      };
    return (
        <>
            <div className="changePasswordModal">

                <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal">
                    <div className="mb-4 form-group col-md-12">
                        <p classname="text">Create your new password.</p>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                        <label className="form-label text">Old Password </label>
                        <input
                            className="form-control id_password"
                            placeholder="Password"
                            name="oldpassword"
                            type={passwordShown ? "text" : "password"}
                            value={formData.oldpassword}
                            onChange={(e) => { handleChange(e) }}
                        />
                        <img src={pass_icon} className="icon_" alt="password" />
                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityoldpassword(!passwordShown) }}>
                            <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                        <label className="form-label text">New Password </label>
                        <input
                            className="form-control id_password"
                            placeholder="Password"
                            name="newpassword"
                            type={passwordShownNewPassword ? "text" : "password"}
                            value={formData.newpassword}
                            onChange={(e) => { handleChange(e) }}
                        />
                        <img src={pass_icon} className="icon_" alt="password" />
                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblitynewpassword(!passwordShownNewPassword) }}>
                            <span className={passwordShownNewPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                    <div className="mb-4 form-group col-md-12">
                        <label classname="form-label text">Confirm Password </label>
                        <input
                            className="form-control id_password"
                            placeholder="Password"
                            name="confirmpassword"
                            type={passwordShownConfirmPassword ? "text" : "password"}
                            value={formData.confirmpassword}
                            onChange={(e) => { handleChange(e) }}
                        />
                        <img src={pass_icon} className="icon_" alt="password" />
                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityconfirmpassword(!passwordShownConfirmPassword) }}>
                            <span className={passwordShownConfirmPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                    <div className='d-grid gap-2 col-6 mx-auto'>
                        &nbsp;&nbsp;&nbsp;
                        <button disabled={isLoading} className=" btn loginBtn m-0" type="submit">
                            {isLoading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            &nbsp;&nbsp;
                            <span>Save Changes</span>
                        </button>
                    </div>
                </form>
            </div>

        </>
    )
}


const mapStateToProps = state => ({
    auth: state.auth
  });
  const mapDispatchToProps = dispatch => ({
    changePassword: bindActionCreators(changePassword, dispatch)
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
