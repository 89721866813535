import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import {user_icon, pen_icon_border,user_full_img, home_black } from '../../../utilities/images';
import { uploadProfilePhoto } from '../../actions/user';
import { addNewKennels } from '../../actions/user/bussinessOwner';
import { updateInfo } from '../../../redux/actions/auth'
 
const AddKennal = (props) => {
    const id = props.id;
    const [kennels, setKennels] = useState();
    console.log("kennelslength",kennels)
    const [loading, setLoading] = useState(false);

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        if (fieldName === "kennels") {
            setKennels(value)
        }
    };

   
    const handleUpdate = () => {
        if (!kennels) {
            toast.error("Please enter kennels");
            return;
        }
        if(kennels <= 0){
            toast.error("Value must be greater than or equal to 1");
            return;
        }
        if(kennels > 25){
            toast.error("Value must be less than or equal to 25");
            return;
        }
        
        let params = {
            id:id,
            token: props.auth.token,
            business_kennels: parseInt(kennels),
        }
        setLoading(true);
        props.addNewKennels(params, res => {
            setLoading(true);
            if (res.status) {
                props.getKennelsDetail();
                props.getBussinessDetail(id);
                toast.success("kennels add successfully");
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
        err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    };


    return (
        <div className="changePasswordModal">
            <form  className="form_horizontal">
                <div className="mb-4 form-group col-md-12">
                    <label className="form-label text">No. Of Kennel </label>
                    <input min={1}
                        max={25}
                        className="form-control"
                        id="inputName"
                        type="number"
                        value={kennels}
                        placeholder="No of Kennels"
                        name="kennels"
                        required
                        onChange={(e) => { onChangeInput(e, 'kennels') }}
                        
                    />
                    <img src={home_black} className="icon_" alt="password" />
                    <span className="toggle_password_ info_icon" >
                        <span id=""></span>
                    </span>
                </div>
                <div className='d-grid gap-2 col-8 mx-auto'>
                    <button className="loginBtn m-0" disabled={loading } type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Add kennels</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    uploadProfilePhoto: bindActionCreators(uploadProfilePhoto, dispatch),
    addNewKennels: bindActionCreators(addNewKennels, dispatch),
    updateInfo: payload => dispatch(updateInfo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(AddKennal);