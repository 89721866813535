import React from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { logout } from '../../../redux/actions/auth';
import footerLogo from "../../public/images/footerLogo.png";


const Footer = (props) => {
    const history = useHistory();
    const userLogout = () => {
        props.logout();
        history.push('/login');
    }

    return (
        <div>
            <footer className='footer'>
            <div className='container'>
                <div className='footerHeader'>
                <div className='row'>
                    <div className='col-md-3'>
                    <Link  to="/"> <img src={footerLogo} className="img-fluid"/> </Link> 
                    </div>
                    <div className='col-md-3'>
                      <h6>Links</h6>
                      <ul>
                        <li><Link  to="/"> Home </Link> </li>
                        <li><Link  to="/"> Services </Link> </li>
                        <li><Link  to="/"> Contact Us </Link> </li>
                      </ul>
                    </div>
                    <div className='col-md-3'>
                      <h6>Info</h6>
                      <ul>
                        <li><Link  to="/"> About </Link> </li>
                        <li><Link  to="/"> Privacy Policy </Link></li>
                        <li><Link  to="/"> Terms Of Use </Link> </li>
                      </ul>
                    </div>
                    <div className='col-md-3'>
                    <div className='footerLast'>
                        <a href="#" className='backToTop'>BACK TO TOP <i className="las la-long-arrow-alt-left"></i></a>
                      <h6>Follow Us</h6>
                      <ul>
                        <li><Link  to="/"><i className="lab la-facebook-f"></i> Facebook </Link>   </li>
                        <li><Link  to="/"><i className="lab la-twitter"></i> Twitter </Link> </li>
                        <li><Link  to="/"><i className="lab la-instagram"></i> Instagram </Link></li>
                      </ul>
                      </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='footerBottom'>
            <div className='row'>
                    <div className="col-md-12">
                        <p className='text-center'>Copyright © 2022 Kennel Tracker. All rights reserved.</p>
                    </div>
                </div>
            </div>
            </footer>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    logout: () =>  dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);