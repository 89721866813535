import React from 'react';

const Report = () => {
  return (
    <div className="changePasswordModal">
        <form  className="form_horizontal">
      
        <div className="mb-4 form-group col-md-12">
            <label className="form-label">Give Reason </label>
            <textarea className='form-control' rows="6"  placeholder="Write...">

            </textarea>
        </div>
        <div className='kannelModalBtn'>
            <button className="noBtn" type="submit">
                    {/* <span className="spinner-border spinner-border-sm"></span> */}
                <span>Cancel</span>
            </button>
            <button className="yesBtn " type="submit">
                    {/* <span className="spinner-border spinner-border-sm"></span> */}
                <span>Report</span>
            </button>
        </div>
        </form>
    </div>
  )
}

export default Report