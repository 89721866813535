import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {
  
  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/forgotPassword" component={Containers.ForgotPassword} />
      <Layouts.NonPrivateRoute exact path="/resetPassword/:id" component={Containers.ResetPassword} />
      <Layouts.NonPrivateRoute exact path="/otp" component={Containers.Otp} />
      <Layouts.PrivateRoute exact path="/userProfile" component={Containers.UserProfile} />

      <Layouts.PrivateRoute exact path="/kennel-bussiness/addbussiness" component={Containers.BussinessInformation} />
      <Layouts.PrivateRoute exact path="/kennel-bussiness/editbussiness/:id" component={Containers.BussinessInformation} />
      <Layouts.PrivateRoute exact path="/kennel-bussiness" component={Containers.KennelBussiness} />
      <Layouts.PrivateRoute exact path="/kennel-bussiness/information/:id" component={Containers.KennelBussinessInfo} />
      <Layouts.PrivateRoute exact path="/kennel-bussiness/kennel-detail/:enterpriseId/:dogId" component={Containers.KennelDetailsInner} />
      <Layouts.PrivateRoute exact path="/reportUser" component={Containers.ReportUser} />

      <Layouts.PrivateRoute exact path="/clients" component={Containers.Clients} />
      <Layouts.PrivateRoute exact path="/clients/information/:id" component={Containers.ClientInformation} />


      <Layouts.PrivateRoute exact path="/dailyReports" component={Containers.DailyReports} />
      <Layouts.PrivateRoute exact path="/dogDetails" component={Containers.DogDetails} />
      <Layouts.PrivateRoute exact path="/trainers" component={Containers.Trainers} />
      <Layouts.PrivateRoute exact path="/staff" component={Containers.Staff} />
      <Layouts.PrivateRoute exact path="/" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/about" component={Containers.About} />
      <Layouts.PrivateRoute exact path="/privacy" component={Containers.Privacy} />
      <Layouts.PrivateRoute exact path="/terms" component={Containers.Terms} />
      <Layouts.PrivateRoute exact path="/edit/:slug" component={Containers.EditCMS} />


      <Layouts.PrivateRoute exact path="/contact" component={Containers.Contact} />

      
      <Route component={Containers.NotFound} />
    </Switch>

  );
}

export default Router;