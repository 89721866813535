import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import {user_icon, pen_icon_border,user_full_img } from '../../../utilities/images';
import { uploadProfilePhoto, getMyDetails ,updateAdminProfile} from '../../actions/user';
import { updateInfo } from '../../../redux/actions/auth'
 
const UserProfileModal = (props) => {
   
    const [image, setImage] = useState(props.auth.userProfile?.userProfileFull?.userInfo?.profilePhoto ?? user_full_img);
    const [fullname, setFullname] = useState(props.auth.userProfile?.userProfileFull?.userInfo?.fullName ?? '');
    const [isLoadingImg, setIsLoadingImg] = useState(false);
    const [loading, setLoading] = useState(false);

    //onChange input
    const onChangeInput = (e, fieldName) => {
        const value = e.target.value;
        const re = /^[A-Za-z ]+$/;
        if (fieldName === "fullname") {
            if(value === "" || re.test(value))
            setFullname(value.trimStart())
        }
    };


    //onChange user profile photo
    const onChangePicture = e => {
        const imageFile = e.target.files[0];
        if (!imageFile) {
            toast.error("Please select an image.");
            return false;
        }
        else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
            toast.error("Please select a valid image.");
            return false;
        }

        let params = {
            profile_image: imageFile,
            token: props.auth.token,
        }
            setIsLoadingImg(true);
            props.uploadProfilePhoto(params, res => {
                setIsLoadingImg(false);
                if (res.status) {
                    setImage(res.data.profile_photo);
                }
                else {
                    toast.error(res.message);
                }
            },
            err => {
                setIsLoadingImg(false);
                toast.error("Something went wrong!");
            });
    };

    const handleUpdate = () => {
        if (!image) {
            toast.error("Please select image");
            return;
        }
        else if (!fullname.trim()) {
            toast.error("Please enter full-name");
            return;
        }
        else if (fullname.length <= 2) {
            toast.error("Name should be maximum 3 character")
            return;
        }
        let params = {
            token: props.auth.token,
            profile_photo: image,
            name: fullname.trim(),
        }

        setLoading(true);
        props.updateAdminProfile(params, res => {
            setLoading(true);
            if (res.status) {
                props.updateInfo(res.data)
                toast.success(res.message);
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
        err => {
            setLoading(false);
            toast.error("Something went wrong!");
        })
    };


    return (
        <div className="changePasswordModal">
            <form  className="form_horizontal">
            <div className="cart_max_inner">
                <div className="cart_max_child text-center">
                        {isLoadingImg ?
                            <div className="spinner-border text-success mt-3 loader_" role="status" >
                                <span className="sr-only" >Loading...</span>
                            </div>
                            :
                            <div className="cart_img">
                                <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => { onChangePicture(e) }} required="" />
                                <label htmlFor="file">
                                    <span className="file-button">
                                        <img src={image} className="img-fluid editProfileImg" alt="user img" />
                                        <img src={pen_icon_border} alt="icon" className="profile_edit" />
                                    </span>
                                </label>
                            </div>
                        }
                </div>
            </div>
            <div className="mb-4 form-group col-md-12">
                    <label className="form-label text">Name </label>
                    <input min={2}
                        maxLength={50}
                        className="form-control"
                        id="inputName"
                        type="text"
                        value={fullname}
                        placeholder="Full Name"
                        name="fullname"
                        required=""
                        onChange={(e) => { onChangeInput(e, 'fullname') }}
                    />
                    <img src={user_icon} className="icon_" alt="password" />
                </div>
                <div className='d-grid gap-2 col-8 mx-auto'>
                    <button className="loginBtn m-0" disabled={loading } type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Save Changes</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    uploadProfilePhoto: bindActionCreators(uploadProfilePhoto, dispatch),
    updateAdminProfile: bindActionCreators(updateAdminProfile, dispatch),
    updateInfo: payload => dispatch(updateInfo(payload)),
});


export default connect(mapStateToProps, mapDispatchToProps)(UserProfileModal);