import React, { useEffect, useState } from 'react';
import CustomModal from "../../components/shared/CustomModal";
import {
    dog_img,
    foot_print,
    report_icon,
    block_icon,
    chat_icon,
    dots_icon,
    mail_icon_fade,
    filter_icon,
    delete_icon,
    delete_icon_black,
    user_icon,
} from "../../../utilities/images";
import { Link, useHistory } from "react-router-dom";
import Report from './report';
import Block from './block';
import { getAllClients, updateStatus } from '../../actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { responsiveFontSizes } from '@material-ui/core';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';

const Clients = (props) => {

    document.title = "Clients";

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [search, setSearch] = useState(null);
    const [filter, setFilter] = useState(null);
    const [key, setKey] = useState(Math.random());
    const [keyPagination, setKeyPagination] = useState(Math.random());
    const [pageCount, setPageCount] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getClients();
    }, []);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1)
        getClients(search, filter, (e.selected + 1));
    }

    const onChangeStatus = (value, item) => {

        const params = {
            id: item._id,
            status: value,
            token: props.auth.token
        }

        swal({
            // title: "Are you sure?",
            text: `Are you sure that you want to change ${item.userInfo?.fullName ?? 'user'} current status to ${value}`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(resp => {
                if (resp) {
                    console.log("updateStatusparams",params);
                    props.updateStatus(params, resp => {
                        console.log("updateStatusresp",resp)
                        if (resp.status) {

                            swal("Success", "Status Updated Successfully", "success");
                            getClients(search, filter, currentPage);
                        }
                        else {
                            toast.error('Something went wrong');
                            getClients(search, filter, currentPage);
                        }
                    },
                        err => {
                            toast.error('Something went wrong');
                        })
                }
            });

    }


    const getClients = (search = null, filter = null, page = 1, limit = 10) => {

        const params = {
            page: page,
            limit: limit,
            search: search,
            filter: filter,
            token: props.auth.token
        }
        setLoading(true);
        props.getAllClients(params, resp => {
            setLoading(false);
            if (resp.status == true) {
                setClients(resp.data.customer)
                setPageCount(resp.data.total_pages)
                setTotalUsers(resp.data.total)

                if (resp.data.total_pages == 1) {
                    setKeyPagination(Math.random())
                }
            }
            else {
                setClients([])
                toast.error('Something went wrong');
                setTotalUsers(0)
            }
        },
            err => {
                setLoading(false);
                setClients([])
                toast.error('Something went wrong');
            }
        )
    }

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)
        getClients(e.target.value, filter)
    }

    const handleFilter = (type) => {
        setFilter(type)
        getClients(search, type)
    }

    return (
        <>
            <section className='stickyHead'>
                <div className="row align-items-center">
                    <div className="col-6 col-sm-3 col-md-3 mb-2 mb-md-0">
                        <h4 className="horizontal_heading_">
                            Clients
                        </h4>
                        <span className="text bussiness">{totalUsers} Total Clients</span>
                    </div>
                    <div className="col-6 col-sm-3 col-md-4 col-xl-4 col-xxl-4 mb-2 mb-md-0">
                        <div className="input-group">
                            {/* <button className="input-group-text" type="button" id="button-addon1">
                        <i className="las la-search serchICON"></i>
                    </button> */}
                            <input className="form-control search__input" placeholder="Search" type="search" value={search || ""} onChange={(e) => handleSearch(e)} />
                        </div>
                    </div>
                    <div className='col-12 col-sm-5 col-md-5 col-xl-5 col-xxl-5 text-md-end mb-2 mb-md-0'>
                        <div className='filterPagination justify-content-md-end'>
                            <div className="filter me-3">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={filter_icon} />&nbsp;&nbsp; {filter ?? 'Filter By'}
                                    </button>
                                    <ul className="dropdown-menu" id='filtertabs'>
                                        <li><a className="dropdown-item" onClick={() => handleFilter(null)}>All</a></li>
                                        <li><a className="dropdown-item" onClick={() => handleFilter('Active')}>Active</a></li>
                                        <li><a className="dropdown-item" onClick={() => handleFilter('Inactive')}>Inactive</a></li>
                                        <li><a className="dropdown-item" onClick={() => handleFilter('Deleted')}>Deleted</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className='customPagination'>
                                <div className='pagination' key={keyPagination}>
                                    <ReactPaginate
                                        breakLabel=""
                                        nextLabel={<i className='fas fa-caret-right'></i>}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={0}
                                        marginPagesDisplayed={0}
                                        pageCount={pageCount}
                                        previousLabel={<i className='fas fa-caret-left'></i>}
                                    // renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='clients'>
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>
                    :
                    <div className='table-responsive'>
                        <table className='table border-0 clientsTable'>
                            <tbody>
                                {clients.length > 0 ?
                                    <>
                                        {clients.map((item, key) => {
                                            return (
                                                <tr role='' className='customTableRow' key={key}>
                                                    <td>
                                                        <Link to={`clients/information/${item._id}`}>
                                                            <div className='kennelHeading'>
                                                                {/* <div className='series'> <h6>#01</h6></div> */}
                                                                <div className='requestBotCard'>
                                                                    <div>
                                                                        <div className='requestBotImgCont'>
                                                                            <img src={item?.userInfo?.profilePhoto ?? dog_img} className='userImg' />
                                                                            <div className='innerBot'>
                                                                                <h6 className='mb-0'>{item?.userInfo?.fullName}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <div className="profileEmail">
                                                            <img src={mail_icon_fade} />
                                                            <span className="text">{item?.email}</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="profileEmail">
                                                            <img src={foot_print} />
                                                            <span className="text">{item?.dog_detail} Dogs</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className='selectContainer dropdown'>
                                                            <div data-bs-toggle='dropdown' aria-expanded='false'
                                                                className={`${item.status == 'Active' ? 'active' :
                                                                    item.status == 'Inactive' ? 'inactive' :
                                                                        item.status == 'Deleted' ? 'deleted' :
                                                                            'inactive'} dropdown-toggle selectContainerToggle`} >
                                                                <span>
                                                                    {item?.status ?? 'Inactive'}
                                                                </span>
                                                            </div>
                                                            <ul className='dropdown-menu status__'>
                                                                <li onClick={() => onChangeStatus('Active', item)} >Active</li>
                                                                <li onClick={() => onChangeStatus('Inactive', item)} >Inactive</li>
                                                                <li onClick={() => onChangeStatus('Delete', item)} >Deleted</li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td className='dropdown'>
                                                        <img src={dots_icon} className='dropdown-toggle' id='reportDropdown' data-bs-toggle='dropdown' aria-expanded='false' />
                                                        <ul className='dropdown-menu reportMenus' aria-labelledby='reportDropdown'>
                                                            <li className='dropdown-item'>
                                                                <Link to={`clients/information/${item._id}`} className="dropdownLinks">
                                                                    <img src={user_icon} /> &nbsp; View
                                                                </Link>
                                                            </li>
                                                            {/* <li className='dropdown-item'
                                                > <img src={delete_icon_black}/> &nbsp; Delete
                                            </li> */}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        <tr role='' className='customTableRow' >
                                            <td colSpan={5}>
                                                No data found
                                            </td>
                                        </tr>

                                    </>}
                            </tbody>
                        </table>
                    </div>
                }
            </section>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                title={modalDetail.title}
                ids={modalDetail.flag == "ReportClient" ? 'ReportClient' : modalDetail.flag == "BlockClient" ? 'DeleteStaff' : ''}
                child={
                    modalDetail.flag == "ReportClient" ? (
                        <Report closeModal={() => handleOnCloseModal()} />
                    ) : modalDetail.flag == 'BlockClient' ?
                        <Block
                            closeModal={() => handleOnCloseModal()}
                        />
                        : ""
                }
                size="lg"
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllClients: bindActionCreators(getAllClients, dispatch),
    updateStatus: bindActionCreators(updateStatus, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
