import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {msg_icon,login_img, landingLogo } from '../../../utilities/images';
import { forgotPassword } from '../../actions/user';
import LoginNavbar from '../../components/LoginNavbar';

    const ForgotPassword = (props) => {
        const history = useHistory();
        const [errorMessages, setErrorMessages] = useState({});
        const [loading, setLoading] = useState(false);
        const [formData, setFormData] = useState({
            email: "",
        });
    
    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }
    
   
    //validation form on email 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
    
        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
            }
        }
        setErrorMessages(errors);
        return formIsValid;
    }
    //form submit on login 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.email) {
            toast.error("Please enter email");
            return;
        }
        else if (formData.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email)) {
            toast.error("Please enter valid email id");
            return;
        }
     
        if (validateForm()) {
            setLoading(true);
    
            let params = {
                email: formData.email.trim(),
            }
            props.forgotPassword(params, res => {
                setLoading(false);
                if (res.status) {
                    toast.success(res.message);
                    history.push('/otp');
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    setLoading(false);
                    toast.error(err.message);
                }
            )
        }
    
    };
    useEffect(() => {
        document.title = "Forgot Password";
    }, []);
    return (
        <>
            {/* <LoginNavbar /> */}
            <section className='commonLogin'>
                <div className='container-fluid '>
                    <div className='row align-items-center'>
                        <div className="col-lg-12">
                            <a className="navbar-brand d-flex justify-content-center">
                                <img src={landingLogo} className="loginLogo" />
                            </a>
                            <div className="loginForm">
                            <button className='backArrow' onClick={() => {history.push('/')}}><i className="las la-long-arrow-alt-left"></i></button>
                                <h4 className="heading">Forgot Password</h4>
                                <p className="text">Enter the email that associated with your account.  We <br className='visible_'/> will send an OTP to recover your password.</p>
                                <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal">
                                    <div className="mb-4 form-group col-md-12">
                                        <label className="form-label">Email </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="email"
                                            placeholder='Email Address'
                                            value={formData.email}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={msg_icon} className="icon_" alt="message" />
                                    </div>
                                    
                                    <div className='d-grid gap-2 col-6 mx-auto mt-2'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Continue</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    forgotPassword: bindActionCreators(forgotPassword, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);