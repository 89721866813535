import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import { landingLogo, login_img, pass_icon } from '../../../utilities/images';
import { resetPassword } from '../../actions/user';
import LoginNavbar from '../../components/LoginNavbar';

const ResetPassword = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [errorMessages, setErrorMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownConfirmPassword, setPasswordShownConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        newpassword: "",
        confirmpassword: "",
    });
    //onchange input field
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };


    //password field show or hide
    const togglePasswordVisiblityConfirmpassword = () => {
        setPasswordShownConfirmPassword(passwordShownConfirmPassword ? false : true);
    };

    //validation form on email password 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;

        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
            }
        }

        if (!formData.password) {
            formIsValid = false;
            errors = { ...errors, password: "Please enter your password." };
        }

        setErrorMessages(errors);
        return formIsValid;
    }
    //form submit on reset password 
    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!formData.newpassword) {
            toast.error("Please enter new password");
            return;
        }
        else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(formData.newpassword)) {
            // Regex with small and capital letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
            // Regex with only small letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
            toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
            return;
          }
        else if (!formData.confirmpassword) {
            toast.error("Please enter confirm password");
            return;
        }
        else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(formData.confirmpassword)) {
            // Regex with small and capital letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
            // Regex with only small letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
            toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
            return;
          }

          else if (formData.confirmpassword !== formData.newpassword) {
            toast.error("Password and confirm password doesn't match ");
            return;
          }
      
        setLoading(true);
        //var UserId = localStorage.getItem("UserId");
        let params = {
            user_id: id,
            new_password: formData.newpassword,
            confirm_password: formData.confirmpassword
        }
        props.resetPassword(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                history.push('/login');
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error(err.message);
            }
        )

    };
    useEffect(() => {
        document.title = "Reset Password";
    }, []);

    return (
        <>
            {/* <LoginNavbar /> */}
            <section className='commonLogin'>
                <div className='container-fluid '>
                    <div className='row align-items-center'>
                        <div className="col-lg-12">
                            <a className="navbar-brand d-flex justify-content-center">
                                <img src={landingLogo} className="loginLogo" />
                            </a>
                            <div className="loginForm">
                                <button className='backArrow' onClick={() => { history.push('/otp') }}><i className="las la-long-arrow-alt-left"></i></button>
                                <h4 className="heading">Reset Password</h4>
                                <p className="text">Create your new password.</p>
                                <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal">
                                    <div className="mb-4 form-group col-md-12">
                                        <label className="form-label">New Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="New Password"
                                            name="newpassword"
                                            type={passwordShown ? "text" : "password"}
                                            value={formData.newpassword}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={pass_icon} className="icon_" alt="password" />
                                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                                            <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                        </span>
                                    </div>
                                    <div className="mb-4 form-group col-md-12">
                                        <label className="form-label">Confirm Password </label>
                                        <input
                                            className="form-control id_password"
                                            placeholder="Confirm Password"
                                            name="confirmpassword"
                                            type={passwordShownConfirmPassword ? "text" : "password"}
                                            value={formData.confirmpassword}
                                            onChange={(e) => { handleChange(e) }}
                                        />
                                        <img src={pass_icon} className="icon_" alt="password" />
                                        <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityConfirmpassword(!passwordShownConfirmPassword) }}>
                                            <span className={passwordShownConfirmPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                        </span>
                                    </div>

                                    <div className='d-grid gap-2 col-6 mx-auto mt-2'>
                                        <button className="loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Reset</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    resetPassword: bindActionCreators(resetPassword, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);