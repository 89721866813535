
import React ,{useState} from 'react';
import CustomModal from "../../components/shared/CustomModal";
import DeleteSuccessfully from './deleteSuccessfully';
const DeleteTrainer = () => {
    document.title = "Delete Trainer";
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    });

    const handleOnCloseModal = () => {
    setModalDetail({
        show: false,
        title: "",
        flag: "",
    });
    setKey(Math.random());
    };
  return (
    <>
    <p className='deleteSub'>Hey! Are you sure? You want to delete Tavares Hammes.</p>
    <div className='kannelModalBtn'>
        <button className='noBtn'>No</button>
        <button className='yesBtn'
        onClick={() => {
            setModalDetail({
              show: true,
              title: "Trainer Deleted ",
              flag: "DeleteSuccessfully",
            });
            setKey(Math.random());
          }}
        >Yes</button>
    </div>
    <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        ids={modalDetail.flag == "DeleteSuccessfully" ?  'DeleteTrainer' : ''}
        child={
          modalDetail.flag == "DeleteSuccessfully" ? (
            <DeleteSuccessfully closeModal={() => handleOnCloseModal()} />
          ) 
          : ""
        }
        size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  )
}

export default DeleteTrainer