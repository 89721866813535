import RestClient from '../../../utilities/RestClient';
import message from '../../constants/messages';
import { AUTH_API_URL } from "../../config";
import { logout } from '../../../redux/actions/auth';

const getApiResponseMessage = (response, defaultErrorMessage = 'Something went wrong!') => {

  let responseMessage = defaultErrorMessage;
  let payload = response.data;  // Data returned by API

  if (payload && Array.isArray(payload) && payload.length > 0) {
    responseMessage = payload[0];
  }
  else if (response.msg) {
    responseMessage = response.msg;
  }
  return responseMessage;
}

const getApiResponse = (result, responseMessage) => {
  let res = {};
  if (result.status === 200 || result.status === 201) {
    res = {
      status: true,
      message: responseMessage,
      statusCode: result.status,
      type: message.success,
      data: result.data.data
    };

  } else {
    res = {
      status: false,
      message: responseMessage,
      type: message.error,
      statusCode: result.status
    };
  }
  return res;
}


/****** action creator for get All bussiness owners ********/
export const getBussinessOwnerList = (params, cb) => {
    return dispatch => {
      RestClient.get(`${AUTH_API_URL}/users/admin/enterprise`, params)
        .then(result => {
          let response = result.data;    // Response returned by server
          let responseMessage = getApiResponseMessage(response);
          let res = getApiResponse(result, responseMessage);
          cb(res);
        })
        .catch(error => {
          let res = {
            status: false,
            message: message.commonError,
            type: message.error
          };
          cb(res);
        });
    };
  };


  /****** get bussiness details admin  ********/
export const getBussinessDetails = (params, cb) => {
  const id = params.id;
  delete params.id;

  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/users/admin/enterprise/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get kennels details admin  ********/
export const getKennelsDetails = (params, cb) => {
  const id = params.id;
  delete params.id;

  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/kennels/admin/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** deleteKannels admin  ********/
export const deleteKannels = (params, cb) => {
  const id = params.id;
  delete params.id;

  return dispatch => {
    RestClient.put(`${AUTH_API_URL}/kennels/admin/delete-kennel/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get trainer details admin  ********/
export const getTrainerDetails = (params, cb) => {
  const enterpriseId = params.id;
  delete params.id;

  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/users/admin/trainer/${enterpriseId}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};



/****** create Enterprise for admin ********/
export const createEnterprise = (params, cb) => {
  return dispatch => {
    RestClient.post(`${AUTH_API_URL}/users/admin/enterprise`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to add care Role. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};

/****** update Enterprise for admin ********/
export const updateEnterprise = (params, cb) => {
  const id = params.id;
  delete params.id;
  delete params.email;
  // delete params.business_kennels;
  return dispatch => {
    RestClient.put(`${AUTH_API_URL}/users/admin/enterprise/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to add care Role. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** add new kennels for admin ********/
export const addNewKennels = (params, cb) => {
  const id = params.id;
  delete params.id;

  return dispatch => {
    RestClient.post(`${AUTH_API_URL}/kennels/admin/add-kennels/${id}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response, 'Unable to add care Role. Please try again later.');
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get dashboard all count details admin  ********/
export const getDashboardCountss = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/dashboards/admin/get-all-count`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get Recently Added Businesss details admin  ********/
export const getRecentlyAddedBusinesss = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/dashboards/admin/recent-business-added`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get inner kennels details admin  ********/
export const getInnerKennelsDetails = (params, cb) => {
  const enterpriseId = params.enterpriseId;
  const dogId = params.dogId;
  delete params.enterpriseId;
  delete params.dogId

  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/kennels/admin/kennel-detail/${enterpriseId}/${dogId}`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


/****** get report users details admin  ********/
export const getReportUsers = (params, cb) => {
  return dispatch => {
    RestClient.get(`${AUTH_API_URL}/reportclients/admin/`, params)
      .then(result => {
        let response = result.data;    // Response returned by server
        let responseMessage = getApiResponseMessage(response);
        let res = getApiResponse(result, responseMessage);
        cb(res);
      })
      .catch(error => {
        let res = {
          status: false,
          message: message.commonError,
          type: message.error
        };
        cb(res);
      });
  };
};


