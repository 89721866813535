import React, { useState, useEffect } from "react";
import CountUp, { useCountUp } from "react-countup";
import { connect } from "react-redux";
import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import landingLeft from "../../public/images/landing_left_img.png";
import smileIcon from "../../public/images/smile_Icon.svg";
import dogTop from "../../public/images/dog_img_top.png";
import dogBottom from "../../public/images/dogBottom.png";
import dogImg_1 from "../../public/images/dog.svg";
import dogImg_2 from "../../public/images/request.svg";
import dogImg_3 from "../../public/images/schedule.svg";
import dogImg_4 from "../../public/images/dogLocation.svg";
import whyChoos from "../../public/images/choos_img.png";
import chooseDog from "../../public/images/chooseDog.png";
import howWeLeft from "../../public/images/how_we_left.png";
import howWeRight from "../../public/images/how_we_right.png";

import cardFootImg from "../../public/images/cardFooterImg.png";
import cardFootImgRed from "../../public/images/cardFooterRed.png";
import starImg from "../../public/images/star_img.png";
import slider_first from "../../public/images/slider-1.png";
import slider_second from "../../public/images/slider-2.png";
// import customerSection from "../../public/images/landing_last_section.png";

// Owl SLider
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const HomePage = props => {
  useCountUp({ ref: "counter", end: 10, duration: 2 });
  const [loading, setLoading] = useState(false);

  const onStartNumberOfClients = () => {
    setLoading(true);
  };
  const onStartPetKennels = () => {
    setLoading(true);
  };

  const onStartClientSatisfaction = () => {
    setLoading(true);
  };

  // const onEnd = () => {
  //     setLoading(false);
  // };

  const containerProps = {
    "aria-busy": loading,
  };

  useEffect(() => {
    document.title = "Home";
    onStartNumberOfClients();
    onStartPetKennels();
    onStartClientSatisfaction();
  }, []);
  return (
    <>
      <div className="banner">
        {/* <Header /> */}
        <section className="firstSection">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="left_section">
                  <img src={landingLeft} className="img-fluid" />
                  <div className="leftBottom">
                    <img src={smileIcon} className="smileIcon" />
                    <p className="mb-0">
                      Already <span className="client">2.5K+</span> Happy
                      Customer
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="right_section">
                  <span className="help">
                    " We help people to caring their pets/dogs "
                  </span>
                  <div className="rightHeading">
                    <img src={dogTop} className="dogTop img-fluid" />
                    <h1>
                      We are <br /> <span className="pet">Pet kennel</span> &{" "}
                      <span className="traning">
                        Training <br />
                      </span>{" "}
                      Agency
                    </h1>
                    <img src={dogBottom} className="dogBottom" />
                    <a href="#" className="btnAgency mt-4">
                      Start Your Pet Training Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="how mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="how_We">
                <img src={howWeLeft} className="img-fluid how_we_left_img" />
                <h4>How We Work</h4>
                <p className="mb-0 how_we_Sub">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. <br />
                  dummy text of the printing and typesetting.
                </p>
                <img src={howWeRight} className="img-fluidc how_we_right_img" />
              </div>
            </div>
            <div className="row cardContainer">
              <div className="col-md-3">
                <div className="how_we_card">
                  <span className="counting">1</span>
                  <img src={dogImg_1} className="dogImg" />
                  <h6>Download the app & Create your pet profile.</h6>
                  <p className="cardSub">
                    Hey! Are you sure? You want to block Kewin William.
                  </p>
                  <img src={cardFootImg} className="cardFooterImg" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="how_we_card active">
                  <span className="counting">2</span>
                  <img src={dogImg_2} className="dogImg" />
                  <h6>
                    Request for your <br /> pet kennel
                  </h6>
                  <p className="cardSub">
                    Hey! Are you sure? You want to block Kewin William.
                  </p>
                  <img src={cardFootImgRed} className="cardFooterImg" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="how_we_card">
                  <span className="counting">3</span>
                  <img src={dogImg_3} className="dogImg" />
                  <h6>
                    Set schedule for <br /> pet pickup
                  </h6>
                  <p className="cardSub">
                    Hey! Are you sure? You want to block Kewin William.
                  </p>
                  <img src={cardFootImg} className="cardFooterImg" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="how_we_card">
                  <span className="counting">4</span>
                  <img src={dogImg_4} className="dogImg" />
                  <h6>
                    After kennel or training <br /> we will drop your pet <br />{" "}
                    on your location
                  </h6>
                  <p className="cardSub">
                    Hey! Are you sure? You want to block Kewin William.
                  </p>
                  <img src={cardFootImg} className="cardFooterImg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="ourkannel">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h4>Our Kennels</h4>
              <p className="how_we_Sub">
                Lorem Ipsum is simply dummy text of the <br /> printing and
                typesetting industry.dummy
              </p>
              <p className="weHave">We have</p>
              <h5>
                Total 100+ Kennels
                <br /> for Pets
              </h5>
              <a href="#" className="btnAgency mt-4">
                Start Your Pet Training
              </a>
            </div>
            <div className="col-md-8">
              <OwlCarousel
                className="owl-theme"
                loop
                margin={80}
                animateOut="fadeOut"
                nav={true}
                Dots={true}

                // navText= {true
                //     // <i className="las la-bell"></i>

                // }
              >
                <div className="item">
                  <img src={slider_first} className="img-fluid" />
                </div>
                <div className="item">
                  <img src={slider_second} className="img-fluid" />
                </div>
                <div className="item">
                  <img src={slider_second} className="img-fluid" />
                </div>
                <div className="item">
                  <img src={slider_second} className="img-fluid" />
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <section className="why_choos">
        <div className="container">
          <div className="row">
            <div className="choose">
              <img src={howWeLeft} className="img-fluid choos_right_img" />
              <h4>Why Choose Kennel Tracker</h4>
              <p className="mb-0 how_we_Sub">
                Lorem Ipsum is simply dummy text of the printing
                <br /> and typesetting industry.dummy
              </p>
              <img src={howWeRight} className="img-fluidc choos_left_img" />
            </div>
          </div>
          <div className="counter">
            <img src={starImg} className="img-fluid starImg" />
            <img src={chooseDog} className="img-fluid chooseDog" />
            <div className="row align-items-center">
              <div className="col-md-3">
                <img src={whyChoos} className="img-fluid chooseImg" />
              </div>
              {/* <CountUp
                                end={123457}
                                duration="3"
                                onStart={onStart}
                                onEnd={onEnd}
                                containerProps={containerProps}
                            /> */}
              {/* <div id="counter" aria-busy={loading} /> */}
              <div className="col-md-3">
                <div className="counter-inner">
                  <h1>
                    <CountUp
                      end={20}
                      duration="3"
                      onStart={onStartNumberOfClients}
                      // onEnd={onEnd}
                      containerProps={containerProps}
                    />
                    K+
                  </h1>
                  <p>Numbers of Clients</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="counter-inner">
                  <h1>
                    <CountUp
                      end={100}
                      duration="3"
                      onStart={onStartPetKennels}
                      // onEnd={onEnd}
                      containerProps={containerProps}
                    />
                    +
                  </h1>
                  <p>Pet Kennels</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="counter-inner">
                  <h1>
                    <CountUp
                      end={96}
                      duration="3"
                      onStart={onStartClientSatisfaction}
                      // onEnd={onEnd}
                      containerProps={containerProps}
                    />
                    %
                  </h1>
                  <p>Client Satisfaction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="customer">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-md-12">
              <div className="landing_last">
                <h4>Why Choose Kennel Tracker</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
