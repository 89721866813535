import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Sidebar from '../../components/shared/Sidebar';
import { dash_1, dash_2, dash_3, request_img, dog_img, user_Img } from '../../../utilities/images';
import Header from '../../components/shared/Header';
import LoginHeader from '../../components/shared/LoginHeader';
import { getDashboardCountss, getRecentlyAddedBusinesss } from '../../actions/user/bussinessOwner';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';


const Dashboard = (props) => {
    document.title = "Dashboard";
    document.body.classList.remove('loginBg');
    const [loading, setLoading] = useState(false);
    const [dashboardCount, setDashboardCount] = useState({});
    const [businesssloading, setBusinesssLoading] = useState(false);
    const [recentlyAddedBusinesss, setRecentlyAddedBusinesss] = useState([]);


    useEffect(() => {
        getDashboardCount();
        getRecentlyAddedBusiness();
    }, []);


    const getDashboardCount = () => {
        const params = {
            token: props.auth.token
        }
        setLoading(true);
        props.getDashboardCountss(params, resp => {
            setLoading(false);
            if (resp.status) {
                setDashboardCount(resp.data)
            }
            else {
                setDashboardCount({})
                toast.error('Something went wrong');
            }
        },
            err => {
                setLoading(false);
                setDashboardCount({})
                toast.error('Sowmthing went wrong');
            }
        )
    }

    const getRecentlyAddedBusiness = () => {
        const params = {
            token: props.auth.token
        }
        setBusinesssLoading(true);
        props.getRecentlyAddedBusinesss(params, resp => {
            setBusinesssLoading(false);
            if (resp.status) {
                setRecentlyAddedBusinesss(resp.data)
            }
            else {
                setRecentlyAddedBusinesss([])
                toast.error('Something went wrong');
            }
        },
            err => {
                setBusinesssLoading(false);
                setRecentlyAddedBusinesss([])
                toast.error('Sowmthing went wrong');
            }
        )
    }


    return (
        <>
            {/* <LoginHeader/> */}
            <section className='stickyHead'>
                <div className="row ">
                    <div className="col-md-12">
                        <h4 className="horizontal_heading_">
                            Home
                        </h4>
                    </div>
                </div>
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>
                    :
                    <div className='row mt-3'>
                        <div className='col-sm-4 col-md-4 mb-2'>
                            <div className='dashCard'>
                                <img src={dash_1} className='dashTop' />
                                <img src={dash_2} className='dashRight' />
                                <img src={dash_3} className='dashBot' />
                                <h2>{dashboardCount.enterpriseCount}</h2>
                                <p className='mb-0'>Total Businesses</p>
                            </div>
                        </div>
                        <div className='col-sm-4 col-md-4 mb-2' >
                            <div className='dashCard dashSecond'>
                                <img src={dash_1} className='dashTop' />
                                <img src={dash_2} className='dashRight' />
                                <img src={dash_3} className='dashBot' />
                                <h2>{dashboardCount.latestEnterpriseCount}</h2>
                                <p className='mb-0'>Business Added This Month</p>
                            </div>
                        </div>
                        <div className='col-sm-4 col-md-4 mb-2'>
                            <div className='dashCard dashThird'>
                                <img src={dash_1} className='dashTop' />
                                <img src={dash_2} className='dashRight' />
                                <img src={dash_3} className='dashBot' />
                                <h2>{dashboardCount.customersCount}</h2>
                                <p className='mb-0'>No. Of Clients</p>
                            </div>
                        </div>
                    </div>
                }
            </section>
            <section className="based mt-3 mb-2 resources_list_">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <p className="horizontal_heading_ mb-2">Recent Kennel Business Added
                        </p>
                        {businesssloading ?
                            <div className="col-12 text-center p-5 mt-5 mb-5">
                                <span className="spinner-border spinner-border-sm"></span>
                            </div>
                            :
                            <div className='requestMainCardContainer'>
                                {recentlyAddedBusinesss.length > 0 ?
                                    <>
                                        {recentlyAddedBusinesss.map((item, index) => {
                                            return (
                                                <div className='requestCardContainer mt-2' key={index}>
                                                    <div className='requestCard'>
                                                        <div className='requestTopCard'>
                                                            <div className='requestCardImg'>
                                                                <img src={item.userInfo.profilePhoto ? item.userInfo.profilePhoto : user_Img} />
                                                            </div>
                                                            <div className='requestCardContent'>
                                                                <h6>{item.userInfo.fullName} </h6>
                                                                <span className='botSpan1 border-0'>{item.email}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <span className='text mt-2'>{moment(item.createdAt).fromNow()}</span>
                                                </div>
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div className='col-md-6 mb-3'>
                        <div className='dashLog'>
                            <p className="horizontal_heading_ mb-2">Logs</p>
                            <div className='logsContentMain'>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                                <div className='logsContent'>
                                    <p className='mb-0'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                    <span className=''>45 min ago</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getDashboardCountss: bindActionCreators(getDashboardCountss, dispatch),
    getRecentlyAddedBusinesss: bindActionCreators(getRecentlyAddedBusinesss, dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);