import { OPEN_NAV, CLOSE_NAV } from "../actions/types";
const initialState = {
    open_nav: false
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    const { type } = action;
    switch (type) {
        case OPEN_NAV:
            return { open_nav: true };
        case CLOSE_NAV:
            return { open_nav: false };
        default:
            return { open_nav: false };
    }
}