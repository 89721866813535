import React, {useState} from 'react'
import {
    dog_img,
    calander_icon,
    dots_icon,
    plus,
    delete_icon_black,
    pen_icon_solo,
    mail_icon_fade,
    report_icon_red
  
  } from "../../../utilities/images";
const DailyReports = () => {
    document.title = "daily-reports";
  return (
    <section className='clients'>
    <div className="container d-flex">
        <div className='content_areas'>
            <div className="row ">
            <div className='col-md-12'>
                <button
                    className="profileBack dogDetailsBack"
                    onClick={() => {
                        // history.push("/dashboard");
                    }}
                    >
                    <i className="las la-long-arrow-alt-left"></i> Back
                </button>
            </div>
                <div className='dailyReport'>
                <button className='newTrannerBtn'
                // onClick={() => {
                //     setModalDetail({
                //       show: true,
                //       title: "Add New Trainer",
                //       flag: "AddTrainer",
                //     });
                //     setKey(Math.random());
                //   }}
                > <img src={report_icon_red}/>&nbsp; &nbsp; Send Daily Report</button>
                </div>
            
               <div className='pageHeadingContainer'>
                
                    <div className='pageHeading'>
                        <h4 className="horizontal_heading_">
                        Daily Reports
                        </h4>
                        {/* <span className="text bussiness">12 Total Trainers</span> */}
                    </div>
               <div >
                
               </div>
            </div>  
           
            </div>  
            <div className='row mt-4'>
                <div className='col-md-12'>
                    <div className='kennelCard mb-2'>
                        <div className='kennelHeading'>
                            {/* <div className='series'> <h6>#01</h6></div> */}
                            <div className='requestBotCard'>
                                <div>
                                    <div className='requestBotImgCont'>
                                        <img src={dog_img}/>
                                        <div className='innerBot'>
                                            <h6 className='mb-0'>Teresa Mitchell</h6>
                                             <span className='text bussiness'>Created By</span> 
                                            {/* <span className='botSpan'>Male 1.5Y</span>  */}
                                        </div>  
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="mediaFile">
                            
                            <span className="text">3 Media Files</span>
                        </div>
                        <div className="profileEmail">
                        <img src={calander_icon}/>&nbsp; <span>Added on 29 Sep, 2022</span>
                        <span className='createSpan'>Created On</span> 
                        </div>
                        <div className='kannelFoot'>
                            <div className='deleteIcon'>
                                <img src={dots_icon} className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"/>
                                <ul className="dropdown-menu view_dropdown" aria-labelledby="dropdownMenuButton1">
                                    <li 
                                    // onClick={() => {
                                    //     setModalDetail({
                                    //     show: true,
                                    //     title: "Edit Trainer Details",
                                    //     flag: "EditTrainer",
                                    //     });
                                    //     setKey(Math.random());
                                    // }}
                                    >
                                        <img src={pen_icon_solo}/> &nbsp; Edit Details</li>
                                    <li 
                                    // onClick={() => {
                                    //     setModalDetail({
                                    //     show: true,
                                    //     title: "Delete Trainer",
                                    //     flag: "DeleteTrainer",
                                    //     });
                                    //     setKey(Math.random());
                                    // }}
                                    > 
                                    <img src={delete_icon_black}/> &nbsp; Delete Trainer</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>                       
        </div>                                                     
    </div>   
    </section>
  )
}

export default DailyReports