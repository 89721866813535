import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import UserProfileModal from "../auth/userProfileModal";
import BussinessInformaiton from "../auth/bussinessInformation";

import { bindActionCreators } from "redux";
import CustomModal from "../../components/shared/CustomModal"
import { toast } from "react-toastify";
import {
  pen_icon,
  request_img,
  msg_icon,
  location_icon,
  logout_icon,
} from "../../../utilities/images";
import ChangePassword from "../auth/changePassword";
import { Link, useHistory } from 'react-router-dom';
import { getUserDetails, userLogOut } from '../../actions/user';

const UserProfile = (props) => {
  document.title = "User Profile";
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({})
  const [businessInfo, setBusinessInfo] = useState({})
  
  const [userInfo, setUserInfo] = useState({})
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });


  console.log(userInfo, "userInfo");
  //get userdetail
  const getUserDetail = (userId) => {
    let params = {
      token: props.auth.token,
    }

    setLoading(true);
    props.getUserDetails(params, (res) => {
      setLoading(false);
      if (res) {
        let detail = res.data.userInfo;
        let businessdetail = res.data.businessInfo
        setUserDetail(detail)
        setUserInfo(res.data)
        setBusinessInfo(businessdetail)
      }
      else {
        toast.error(res.message);
      }
    },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      })
  }



  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //user logout 
  const userLogout = () => {
    let params = {
      token: props.auth.token
    }
    props.userLogout(params, res => {
      if (res.status) {
        toast.success(res.message);
        history.push('/login');
      }
      else {
        toast.error(res.message);
      }
    },
      err => {
        toast.error(err.message);
      })
  }

  useEffect(() => {
    getUserDetail();
    return () => {
      setUserDetail({});
      setUserInfo({});
    }
  }, [])

  return (
    <>

      {loading ?
        <div className="col-12 text-center p-5 mt-5 mb-5">
          <span className="spinner-border spinner-border-sm"></span>
        </div>
        :
        <>
          <section className="case">
            <div className="container">
              <div className="row justify-content-center mb-2">
                <div className="col-8">
                  <div className="back_arrow d-flex">
                    <button className='profileBack' onClick={() => { history.push('/dashboard') }}  ><i className="las la-long-arrow-alt-left"></i> Back</button>
                  </div>
                </div>
              </div>
              <div className="row  justify-content-center">
                <div className="col-md-8">
                  <div className="card" id="case_card">
                    <div className="card-body">
                      <div className="userProfile">
                        <h6 className="profileHeading_">My Profile</h6>
                        <img src={pen_icon}
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          title: "Edit Profile",
                          flag: "userProfile",
                        });
                        setKey(Math.random());
                      }}
                    />
                      </div>
                      <div className='mt-2'>
                        <div className='requestCard'>
                          <div className='requestTopCard'>
                            <div className='requestCardImg '>
                              <img className="profilepic" src={userDetail.profilePhoto ? userDetail.profilePhoto : request_img} />
                            </div>
                            <div className='requestCardContent'>
                              <h6>{userDetail.fullName}</h6>
                              <span className='text bussiness'>{userInfo.role}</span>
                            </div>
                          </div>
                        </div>
                        <div className='profileChangePass'>
                          <div className='profileEmail'>
                            <img src={msg_icon} /><span className='text'>{userInfo.email}</span>
                          </div>
                          <div className='changePassLink'>
                            <button onClick={() => {
                              setModalDetail({
                                show: true,
                                title: "Change Password",

                                flag: "changePassword",
                              });
                              setKey(Math.random());
                            }} className='changePassBtn'>Change Password</button>
                          </div>
                        </div>
                        <hr className='hr' />
                        <div className="userProfile">
                          <h6 className="profileHeading_">Business Information</h6>
                          <img src={pen_icon}
                      onClick={() => {
                        setModalDetail({
                          show: true,
                          title: "Edit Business Details",
                          flag: "bussinessInformation",
                        });
                        setKey(Math.random());
                      }}
                      />
                      </div>
                        <div className='requestCard mt-3'>
                          <div className='requestTopCard'>
                            <div className='requestCardImg'>
                              <img className="profilepic" src={businessInfo.businessPhoto ? businessInfo.businessPhoto :   request_img} />
                            </div>
                            <div className='requestCardContent'>
                              <h6>{userInfo.businessInfo ? userInfo.businessInfo.name : ""}</h6>
                              <span className='text bussiness'><img src={location_icon} className="locationIcon" />{userInfo.businessInfo ? userInfo.businessInfo.address : ""}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='specification'>
                        <p>Specifications</p>
                        {userInfo && userInfo?.businessInfo && userInfo?.businessInfo?.specifications.map((value, index) =>
                          <ul key={index}>
                            <li>{value?.title}</li>
                          </ul>
                        )
                        }
                      </div>
                    </div>
                    <button className='changePassBtn logout' onClick={() => { userLogout() }}><img src={logout_icon} /> Log Out</button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      }
       <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        ids={modalDetail.flag == "changePassword" ?  'changePassword' :  modalDetail.flag == 'userProfile' ? 'userProfile' : modalDetail.flag == 'bussinessInformation' ? 'bussinessInformation' : ''}
        child={
          modalDetail.flag == "changePassword" ? (
            <ChangePassword closeModal={() => handleOnCloseModal()} />
          ) :   modalDetail.flag == 'userProfile' ? 
            <UserProfileModal
            closeModal={() => handleOnCloseModal()}
            />
          :
          modalDetail.flag == 'bussinessInformation' ? 
            <BussinessInformaiton
            closeModal={() => handleOnCloseModal()}
            />
          : ""
        }
        size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
    </>

  ) 
}


const mapStateToProps = state => ({
  auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
  userLogout: bindActionCreators(userLogOut, dispatch),
  getUserDetails: bindActionCreators(getUserDetails, dispatch),

});
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
