import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { dots_icon, home_iconDark, location_icon, user_icon, user_profile } from '../../../utilities/images';
import Kennels from './kennels';
import Trainners from './trainners';
import { updateStatus } from "../../actions/user";
import { getBussinessDetails } from '../../actions/user/bussinessOwner';
import { toast } from "react-toastify";
import swal from 'sweetalert';

const KennelBussinessInfo = (props) => {
    document.title = "Kennel-Bussiness-Info";
    const { id } = useParams();

    const [activeTab, setActiveTab] = useState('kennels');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    useEffect(() => {
        getBussinessDetail(id);
    }, [id]);


    const getBussinessDetail = (id) => {
        const params = {
            id: id,
            token: props.auth.token
        }
        setLoading(true);
        props.getBussinessDetails(params, resp => {
            setLoading(false);
            if (resp.status == true) {
                setData(resp.data)
            }
            else {
                setData([])
                toast.error('Sowmthing went wrong');
            }
        },
            err => {
                setLoading(false);
                setData({})
                toast.error('Sowmthing went wrong');
            }
        )
    }

    const onChangeStatus = (value, item) => {

        const params = {
            id: item._id,
            status: value,
            token: props.auth.token
        }

        swal({
            // title: "Are you sure?",
            text: `Are you sure that you want to change ${item.userInfo?.fullName ?? 'user'} current status to ${value}`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(resp => {
                if (resp) {

                    props.updateStatus(params, resp => {
                        if (resp.status) {
                            swal("Success", "Status Updated Successfully", "success");
                            getBussinessDetail(id);
                        }
                        else {
                            toast.error('Something went wrong');
                            getBussinessDetail(id);
                        }
                    },
                        err => {
                            toast.error('Something went wrong');
                        })
                }
            });

    }


    return (
        <div className='kennelBusinessInfo'>
            <section className='stickyHead'>
                <div className="row justify-content-center mb-3">
                    <div className="col-md-12">
                        <div className="back_arrow d-flex">
                            <Link to='/kennel-bussiness'>
                                <button className="profileBack mb-2">
                                    <i className="las la-long-arrow-alt-left"></i>Back
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* Start KennelBusinessInfo Table */}
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>
                    :
                    <div className='table-responsive'>
                        <table className='table border-0 kennelTable'>
                            <tbody>
                                <tr role='' className='customTableRow'>
                                    <td>
                                        <div className='requestBotCard'>
                                            <div>
                                                <div className='requestBotImgCont'>
                                                    <img src={data.businessInfo?.businessPhoto ?? user_profile}
                                                        className='user' alt='UserImg' />
                                                    <div className='innerBot'>
                                                        <h6 className='mb-0'>{data.businessInfo?.name}</h6>
                                                        <span className='botSpan1 border-0'>
                                                            <img src={location_icon} alt='locationIcon' className='icons_ me-2' />
                                                            {data.businessInfo?.address}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='businessDetails mt-3'>
                                                    <img src={home_iconDark} alt='HomeIcon' className='me-2' style={{ verticalAlign: 'baseline' }} />
                                                    <span className='innersubTxt'>No. Of Kennels : <span className='innersubOuter'>{data.businessInfo?.kennels}</span></span>
                                                    <div className='btn-inline mt-2'>
                                                        {data.businessInfo?.specifications?.length > 0 ?
                                                            <>
                                                                {data.businessInfo?.specifications?.map((spec, index) => {
                                                                    return (
                                                                        <button className='bussinesBtns' key={index}>{spec.title}</button>
                                                                    )
                                                                })}
                                                            </>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='requestBotCard'>
                                            <div>
                                                <div className='requestBotImgCont'>
                                                    <img
                                                        src={data.businessInfo?.businessPhoto ?? user_profile}
                                                        className='userImg' />
                                                    <div className='innerBot'>
                                                        <h6 className='mb-0'>{data.userInfo?.fullName}</h6>
                                                        <span className='botSpan1 border-0'>Business Owner</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className='selectContainer dropdown'>
                                            <div data-bs-toggle='dropdown' aria-expanded='false'
                                                className={`${data.status == 'Active' ? 'active' :
                                                    data.status == 'Inactive' ? 'inactive' :
                                                        data.status == 'Deleted' ? 'deleted' :
                                                            'inactive'} dropdown-toggle selectContainerToggle`} >
                                                <span>
                                                    {data?.status ?? 'Inactive'}
                                                </span>
                                            </div>
                                            <ul className='dropdown-menu status__'>
                                                <li onClick={() => onChangeStatus('Active', data)} >Active</li>
                                                <li onClick={() => onChangeStatus('Inactive', data)} >Inactive</li>
                                                <li onClick={() => onChangeStatus('Delete', data)} >Deleted</li>
                                            </ul>
                                        </div>
                                    </td>
                                    {/* <td className=''>
                                        <img src={dots_icon} className='dropdown-toggle' id='reportDropdown' data-bs-toggle='dropdown' aria-expanded='false' />
                                        <ul className='dropdown-menu reportMenus' aria-labelledby='reportDropdown'>
                                            <li className='dropdown-item'>
                                                <Link to={'clients/information'} className="dropdownLinks">
                                                    <img src={user_icon}/> &nbsp; View
                                                </Link>
                                            </li>
                                            {/* <li className='dropdown-item'
                                                > <img src={delete_icon_black}/> &nbsp; Delete
                                            </li> *}
                                        </ul>
                                    </td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }

                {/* End KennelBusinessInfo Table */}
            </section>
            {/* Start KennelsDetailsContainer Tabs */}
            <div className='kennelsDetailsContainer'>
                {/* Start KennelsInfo Tabs */}
                <div className='kennelsTabs'>
                    <ul className='kennelsInfoMenus'>
                        <li className='kennelsInfoItems me-3'>
                            <button className={`${activeTab == 'kennels' ? 'active' : ''} kennelsInfoLinks`}
                                onClick={() => {
                                    setActiveTab('kennels')
                                }}
                            >Kennels</button>
                        </li>
                        <li className='kennelsInfoItems'
                        >
                            <button className={`${activeTab == 'trainners' ? 'active' : ''} kennelsInfoLinks`}
                                onClick={() => {
                                    setActiveTab('trainners')
                                }}
                            >Trainers</button>
                        </li>
                    </ul>
                </div>
                {/* End KennelsInfo Tabs */}
                {/* Start KennelsTabsDetails Tabs */}
                <div className='kennelsTabsDetails'>
                    {activeTab == 'kennels'
                        ?
                        <Kennels getBussinessDetail={getBussinessDetail} />
                        :
                        <Trainners />
                    }
                </div>
                {/* End KennelsTabsDetails Tabs */}
            </div>
            {/* End KennelsDetailsContainer Tabs */}

        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getBussinessDetails: bindActionCreators(getBussinessDetails, dispatch),
    updateStatus: bindActionCreators(updateStatus, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(KennelBussinessInfo);
