import * as TYPE from './types'

export const openNav = () => {
    return {
        type:TYPE.OPEN_NAV,
    }
}

export const closeNav = () => {
    return {
        type:TYPE.CLOSE_NAV
    }
}