import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { Link, useHistory ,useParams, useLocation} from 'react-router-dom';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {user_icon, pen_icon_border,user_full_img,location_big_icon, home_black, msg_icon, user_Img} from '../../../utilities/images';
import { uploadProfilePhoto } from '../../actions/user';
import { getBussinessDetails , updateEnterprise, createEnterprise } from '../../actions/user/bussinessOwner';
import swal from 'sweetalert';

let nextId = 0;

const BussinessInformation = (props) => {
  document.title = "Bussiness Infromation";
  const { id } = useParams();
  const history = useHistory();
  const [image, setImage] = useState("");
  const [businessImage, setBusinessImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(false);
  const [name, setName] = useState('');
  const [specifications, setSpecifications] = useState([]);
  const [businessInfo, setBusinessInfo] = useState({
    business_name: "",
    name: "",
    kennels: "",
    email: "",
    description: "",
  });
  console.log("businessInfo",businessInfo);
  const [address, setAdress] = useState("");
  const [businessAdress, setBusinessAdress] = useState("");
  const [cordinates, setCordinates] = useState({
    lat: null,
    lng: null
  });
  


  useEffect(()=>{
    if(id){
        getBussinessDetail(id);
    }   
  },[id]);

  const getBussinessDetail = (id) => {
    const params={
        id:id,
        token:props.auth.token
    }
    props.getBussinessDetails(params, resp => {
            if(resp.status == true){
                setBusinessInfo({ ...businessInfo, "business_name": resp.data.businessInfo.name ,
                "name": resp.data.userInfo.fullName, "kennels": resp.data.businessInfo.kennels,
                "email": resp.data.email, "description": resp.data.businessInfo.description})
                setAdress(resp.data.address.streetAddress);
                setBusinessAdress(resp.data.businessInfo.address)
                let specifications = resp.data.businessInfo.specifications;
                setSpecifications(specifications);
                setImage(resp.data.userInfo.profilePhoto);
                setBusinessImage(resp.data.businessInfo.businessPhoto);
            }
            else{
                toast.error('Sowmthing went wrong');
            }
        },
        err => {
            toast.error('Sowmthing went wrong');
        }
    )
  } 


  const handleSelect = async (value, flag) => {
    if(flag == "address"){
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setAdress(value)
        setCordinates(ll)
    }
    else {
        const result = await geocodeByAddress(value);
        const ll = await getLatLng(result[0]);
        setBusinessAdress(value)
        setCordinates(ll)
    }
  }

  const handleAdd = (e) => {
    setName('');
    let letterVal = /^[a-zA-Z\s]*$/;
    for(let ele of specifications){
        if(name==ele.title){
            toast.error("Please don't add duplicate specification.");
            return;
        } 
    }
    if(name.length <= 2) {
        toast.error("Specifications should be maximum 3 character")
        return;
    }
    else if(!name.match(letterVal)){
        toast.error('Please enter Specifications alphabet characters only')
        return;
    }

       else if (name!=specifications.title) {
        specifications.unshift(
            {
                title: name.trim(),
                _id: nextId++
            } 
        ) 
}  
}



  // delete Specification
  const deleteSpecification = (id) => {
    swal({
        // title: "Are you sure?",
        text: `Are you sure want to remove this Specification?`,
        // icon: "warning",
        dangerMode: false,
        buttons: true,
    })
        .then(resp => {
            if (resp) {
                let result = specifications.filter(item => item._id !== id);
                setSpecifications(result)
            }
            else {

            }
        });
  };

  //onChange input
  const onChangeInput = (e, fieldName) => {
    const value = e.target.value;
    if (fieldName === "fullname") {
        setBusinessInfo({ ...businessInfo, "business_name": value.trimStart()})
    }
    else if (fieldName === "ownername") {
        setBusinessInfo({ ...businessInfo, "name": value.trimStart() })
    }
    else if (fieldName === "kennels") {
        setBusinessInfo({ ...businessInfo, "kennels": value.trimStart()})
    }
    else if (fieldName === "email") {
        setBusinessInfo({ ...businessInfo, "email": value.trim() })
    }
    else if (fieldName === "description") {
        setBusinessInfo({ ...businessInfo, "description": value.trimStart() })
    }
  };

  //form submit 
  const handleSubmit = (e) => {
    e.preventDefault();
    let letterVal = /^[a-zA-Z\s]*$/;
    if (!image) {
        toast.error("Please select profile image ");
        return;
    }
    else if (!businessInfo.name) {
        toast.error("Please enter Owner name");
        return;
    }
    else if (!businessInfo.email) {
        toast.error("Please enter email ");
        return;
    }
    else if (!address) {
        toast.error("Please enter user location");
        return;
    }
    else if (!businessImage) {
        toast.error("Please select business image ");
        return;
    }
    else if (!businessInfo.business_name) {
        toast.error("Please enter business name");
        return;
    } 
    
    else if (!businessInfo.kennels) {
        toast.error("Please enter no of kennels");
        return;
    }
    // else if (businessInfo.kennels.length <= 0 && businessInfo.kennels.length > 25) {
    //     toast.error("Please enter no of kennels between 1 to 25");
    //     return;
    // }
    else if (!businessAdress) {
        toast.error("Please enter business location");
        return;
    }
    
    else if (!businessInfo.description.trim()) {
        toast.error("Please enter business description");
        return;
    }
    else if (!specifications.length > 0) {
        toast.error("Please enter specification.");
        return;
    }
    else if (businessInfo.name.length <= 2) {
        toast.error("Name should be maximum 3 character")
        return;
    }
    else if(!businessInfo.name.match(letterVal)){
        toast.error('Please enter alphabet characters only')
        return;
    }
    setLoading(true);
    let spec = specifications.map((item) => {
        return { title: item.title }
    });
    let params = {
        token: props.auth.token,
        name:businessInfo.name,
        email: businessInfo.email,
        address: address,
        business_name: businessInfo.business_name,
        business_address: businessAdress,
        business_description: businessInfo.description,
        profile_photo: image,
        business_photo: businessImage,
        specifications: spec
    }
    setLoading(true);
    if(id){
        params = { ...params, id: id, business_kennel: businessInfo.kennels}
        props.updateEnterprise(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                history.push("/kennel-bussiness")
            }
            else {
                toast.error(res.message);
            }

        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    }
    else{
        params = { ...params, business_kennels: businessInfo.kennels}
        props.createEnterprise(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                history.push("/kennel-bussiness")
            }
            else {
                toast.error(res.message);
            }

        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })
    }

  };


  //onChange user profile photo
  const onChangePicture = (e, flag) => {    
    const imageFile = e.target.files[0];
    if (!imageFile) {
        toast.error("Please select an image.");
        return false;
    }
    else if (!imageFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
        toast.error("Please select a valid image.");
        return false;
    }

    let params = {
        profile_image: imageFile,
        token: props.auth.token,
    }
        setIsLoadingImg(true);
        props.uploadProfilePhoto(params, res => {
            setIsLoadingImg(false);
            if (res.status) {
                if(flag){
                    setBusinessImage(res.data.profile_photo);  
                }
                else{
                    setImage(res.data.profile_photo);
                }
            }
            else {
                toast.error(res.message);
            }
        },
        err => {
            setIsLoadingImg(false);
            toast.error("Something went wrong!");
        });
  };

  
  return (
   <div className='container'>
        <div className="row justify-content-center mb-2">
            <div className="col-md-8">
                <div className="back_arrow d-flex mb-3">
                    <Link to='/kennel-bussiness' className="profileBack">
                        <i className="las la-long-arrow-alt-left"></i> Back
                    </Link>
                </div>
            </div>
        </div>
        <div className="newBussiness">
           <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <form  className="form_horizontal bussinessInfoForm row" onSubmit={(e) => { handleSubmit(e) }}>
                        {id ? 
                         <>
                            <h4 className="contactSub mb-3 sm-mb-0">
                                    Edit User Information
                            </h4>
                         </>
                        : 
                        <>
                            <h4 className="contactSub mb-3 sm-mb-0">
                                    User Information
                            </h4>
                            </>
                        } 
                            <div className="cart_max_inner mb-3">
                                <div className="cart_max_child text-center">
                                <div className="cart_img">
                                    <input type="file" id="file" accept="image/*" className="form-control d-none" onChange={(e) => {onChangePicture(e) }}/>
                                    <label htmlFor="file">
                                            <span className="file-button">
                                                <img src={image ? image : user_Img} className="editProfileImg" alt="user img" />
                                                <img src={pen_icon_border} alt="icon" className="profile_edit" />
                                            </span>
                                    </label>
                                </div>
                                </div>
                            </div>
                           

                        <div className="mb-3 form-group col-md-6">
                            <label className="form-label text">Owner Name </label>
                            <input min={2}
                                maxLength={50}
                                className="form-control"
                                id="inputName"
                                type="text"
                                value={businessInfo.name}
                                placeholder="Owner Name"
                                name="ownername"
                                required=""
                                onChange={(e) => { onChangeInput(e, 'ownername') }}
                            />
                            <img src={user_icon} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon" >
                                <span id=""></span>
                            </span>
                        </div>

                        <div className="mb-3 form-group col-md-6">
                            <label className="form-label text">Email </label>
                            <input
                                className="form-control"
                                id="inputName"
                                type="text"
                                name="email"
                                placeholder='Email Address'
                                value={businessInfo.email}
                                required=""
                                onChange={(e) => { onChangeInput(e, 'email') }}
                                disabled={id}
                            />
                            <img src={msg_icon} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon" >
                                <span id=""></span>
                            </span>
                        </div>
                        <div className="mb-3 form-group col-md-12">
                            <label className="form-label text">Location </label>
                            <PlacesAutocomplete
                                value={address}
                                onChange={setAdress}
                                onSelect={(e) => { handleSelect(e, 'address') }}
                                //onSelect={handleSelect}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div
                                        key={suggestions.description}
                                    >
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input form-control textArea',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <img src={location_big_icon} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon">
                                <span id=""></span>
                            </span>
                        </div>

                        {id ? 
                         <>
                            <h4 className="contactSub mb-3 sm-mb-0">
                                    Edit Business Information
                            </h4>
                         </>
                        : 
                        <>
                            <h4 className="contactSub mb-3 sm-mb-0">
                                    Business Information
                            </h4>
                            </>
                        } 
                        
                        <div className="cart_max_inner mb-3">
                            <div className="cart_max_child text-center">
                            <div className="cart_img">
                                <input type="file" id="fileTwo" accept="image/*" className="form-control d-none" onChange={(e) => {onChangePicture(e , "business") }} />
                                <label htmlFor="fileTwo">
                                        <span className="file-button">
                                            <img src={businessImage ? businessImage : user_Img} className="editProfileImg" alt="business img" />
                                            <img src={pen_icon_border} alt="icon" className="profile_edit" />
                                        </span>
                                </label>
                            </div>
                            </div>
                        </div>
                        <div className="mb-3 form-group col-md-6">
                            <label className="form-label text">Business Name </label>
                            <input min={2}
                                maxLength={50}
                                className="form-control"
                                id="inputName"
                                type="text"
                                value={businessInfo.business_name}
                                placeholder="Full Name"
                                name="fullname"
                                required=""
                                onChange={(e) => { onChangeInput(e, 'fullname') }}
                            />
                            <img src={home_black} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon" >
                                <span id=""></span>
                            </span>
                        </div>
                        
                        <div className="mb-3 form-group col-md-6">
                            <label className="form-label text">No. Of Kennel </label>
                            <input min={1}
                                // maxLength={25}
                                max={25}
                                className="form-control"
                                id="inputName"
                                type="number"
                                value={businessInfo.kennels}
                                placeholder="No of Kennels"
                                name="kennels"
                                required=""
                                onChange={(e) => { onChangeInput(e, 'kennels') }}
                                disabled={id}
                            />
                            <img src={home_black} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon" >
                                <span id=""></span>
                            </span>
                        </div>
                        <div className="mb-3 form-group col-md-12">
                            <label className="form-label text">Location </label>
                            <PlacesAutocomplete
                                value={businessAdress}
                                onChange={setBusinessAdress}
                                //onSelect={handleSelect}
                                onSelect={(e) => { handleSelect(e, 'businessAdress') }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div
                                        key={suggestions.description}
                                    >
                                        <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input form-control textArea',
                                            })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            <img src={location_big_icon} className="icon_" alt="password" />
                            <span className="toggle_password_ info_icon">
                                <span id=""></span>
                            </span>
                        </div>
                        <div className="mb-3 form-group col-md-12">
                            <label className="form-label text">Description </label>
                            <textarea
                                className='form-control secondTextArea' rows="6"
                                id="inputName"
                                type="text"
                                value={businessInfo.description}
                                placeholder="Enter description"
                                name="description"
                                required=""
                                onChange={(e) => { onChangeInput(e, 'description') }}
                            />
                        </div>
                        <div className="mb-4 form-group col-md-12">
                            <label className="form-label text">Add Specifications </label>
                            <div style={{position:'relative'}}>
                                <input
                                    className="form-control id_password specificationInput"
                                    value={name}
                                    maxLength={15}
                                    onChange={e => setName(e.target.value)}
                                />
                                <button
                                    disabled={!name.trim()}
                                    onClick={(e) =>
                                        handleAdd(e)
                                    }
                                    className="addBtn_">Add
                                </button>
                            </div>
                            <span className="toggle_password_ info_icon">
                                <span id=""></span>
                            </span>
                            <ul className='addBtnList'>
                                {specifications.map((specifications, key ) => (
                                    
                                    <li className='listTxt' key={key}>
                                        <span>{specifications.title}</span> &nbsp;
                                        <span onClick={() => { deleteSpecification(specifications._id) }}>
                                        <i className="las la-times" aria-hidden="true"></i></span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='col-md-12 text-end mb-5'>
                            <div className='footerBtns'>
                                <Link to='/kennel-bussiness'>
                                <button className="rejectBtn me-3" type="submit">
                                    <span>Cancle</span>
                                </button></Link>
                                <button className="accptBtn m-0" type="submit">
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                    {id ? <span>update</span> : <span>Add</span>}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
           </div>
        </div>
   </div>
  )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    uploadProfilePhoto: bindActionCreators(uploadProfilePhoto, dispatch),
    createEnterprise: bindActionCreators(createEnterprise, dispatch),
    updateEnterprise: bindActionCreators(updateEnterprise, dispatch),
    getBussinessDetails: bindActionCreators(getBussinessDetails, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps)(BussinessInformation);
