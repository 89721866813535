import React from 'react'

const BlockSuccessfully = () => {
  return (
    <>
    <p className='deleteSub'>Hey! You Successfully blocked Kewin William.</p>
    <div className='kannelModalBtn'>
        <button className='yesBtn'
        >Okay</button>
    </div>
    </>
  )
}

export default BlockSuccessfully