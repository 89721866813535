import React ,{useState} from 'react';
import CustomModal from "../../components/shared/CustomModal";
import DogDetailsDelete from './delete';
import {
    delete_icon,
    request_img,
    msg_icon,
    location_icon_black,
    chat_icon,
    dog_img,
    calander_icon
  } from "../../../utilities/images";
    const DogDetails = () => {
    document.title = "dog details";
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
    });

    const handleOnCloseModal = () => {
    setModalDetail({
        show: false,
        title: "",
        flag: "",
    });
    setKey(Math.random());
    };
  return (
    <>
    <div className="container d-flex">
    <div className='content_areas'>
    <section className="dogDetails">
        <div className="container">
        <div className="row justify-content-center mb-2">
            <div className="col-12">
            <div className="back_arrow d-flex justify-content-between">
                <div className='backArrowContainer'>
                <button
                className="profileBack dogDetailsBack mb-3"
                onClick={() => {
                    // history.push("/dashboard");
                }}
                >
                <i className="las la-long-arrow-alt-left"></i> Back
                </button>
                </div>
                <button
                className="profileBack dogDetailsBack text-decoration-underline mb-3"
                onClick={() => {
                    // history.push("/dashboard");
                }}
                >
                Daily Reports
                </button>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
            <div className="card" id="dogDetails">
                <div className="card-body">
                <div className="userProfile">
                    <div className='dogDetailsHeading'>
                        <h6 className="profileHeading_">Dog Details</h6>
                        <h6>#01</h6>
                    </div>
                    <img src={delete_icon
                    }
                    onClick={() => {
                        setModalDetail({
                          show: true,
                          title: "Kennel Deleted",
                          flag: "DeleteKennel",
                        });
                        setKey(Math.random());
                      }}
                    />
                </div>
                <div className="mt-2">
                <div className='requestBotImgCont'>
                    <img src={dog_img}/>
                    <div className='innerBot'>
                        <h6 className='mb-0'>Kesper</h6>
                        <span className='botSpan1'>Beagle</span> 
                        <span className='botSpan'>Male 1.5Y</span> 
                        <div className='kannelCalander'>
                            <img src={calander_icon}/>&nbsp;&nbsp; <span>Added on 29 Sep, 2022</span>
                        </div>
                    </div>                  
                </div>
                <div className='dogDetailsList mt-2'>
                    <div className='row'>
                        <div className='col-md-3'>
                        <div className="specification">
                            <p>Allergies</p>
                            <ul>
                                <li>Sneezing</li>
                                <li>Postnasal Drip</li>
                                <li>Cough</li>
                            </ul>
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="specification">
                            <p>Sleeping Cycle</p>
                            <ul>
                                <li>14 - 18 hrs</li>
                            </ul>
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="specification">
                            <p>Injuries</p>
                            <ul>
                                <li>Eye Trauma</li>
                                <li>Heat Stroke/Dehydration</li>
                                <li>Torn or Broken Nail</li>
                            </ul>
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className="specification">
                            <p>Favorite Food</p>
                            <ul>
                                <li>Chees</li>
                                <li>Peanut Butter</li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
                
                    <hr className="hr" />
                    <div className='dogDetailsFoot'>
                    
                    <div className="requestCard mt-3">
                    <div className="requestTopCard">
                        <div className="requestCardImg">
                        <img src={request_img} />
                        </div>
                        <div className="requestCardContent">
                        <h6>Kennel Business Name</h6>
                        <span className="text bussiness">
                            
                            Owner
                        </span>
                        </div>
                    </div>
                    
                    </div>
                    <img src={chat_icon} />
                    </div>
                    <div className="profileEmail mt-3">
                        <img src={msg_icon} />
                        <span className="text">kewin885@gmail.com</span>
                      </div>
                      <div className="profileEmail">
                        <img src={location_icon_black} />
                        <span className="text">191 Spencer Plaza Hilll Wall Abbottbury 19634-0511</span>
                      </div>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    </div>
    </div>
    <CustomModal
    key={key}
    show={modalDetail.show}
    backdrop="static"
    title={modalDetail.title}
    ids={modalDetail.flag == "DeleteKennel" ?  'DeleteTrainer' : ''}
    child={
      modalDetail.flag == "DeleteKennel" ? (
        <DogDetailsDelete closeModal={() => handleOnCloseModal()} />
      ) 
      : ""
    }
    size="lg"
    // footerContent = {}
    // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
    onCloseModal={() => handleOnCloseModal()}
  />
  </>
  )
}

export default DogDetails