import React from 'react';
import { connect } from 'react-redux';
import { Link,useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import landingLogo from "../../public/images/logo.svg";

const Header = (props) => {
    const history = useHistory();
    return (
        <>
       {/* START header  */}

        <header>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src={landingLogo}/></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i className="fas fa-bars"></i></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                        <a className="nav-link active"  href="#">Home</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="#">About</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link " href="#" >Services</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link " href="#" >Contact Us</a>
                        </li>
                        <li className="nav-item ">
                            <a href="#" className="btnTheme">Download Customer Mobile App</a>
                        </li>
                        <li className="nav-item ">
                          <Link  to="/login" className="btnAgency">Agency Login </Link>
                        </li>
                    </ul>
                    
                    </div>
                </div>
                </nav>
            </div>
        </header>
{/* END header  */}
               
           
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
 
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);