import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import LoginHeader from './LoginHeader';
import Sidebar from './Sidebar';

const AuthLayout = (props) => {
    const pathName = window.location.pathname;
    const authRoutes = ['/login','/forgotPassword','/resetPassword','/otp',];
   
    document.body.classList.add('loginBg');

    return (
            <>
                {authRoutes.includes(pathName) ?
                    <>  
                        {props.children}  
                    </>
                        :
                    <>
                        {props.children}
                    </>
                }
            </>


    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);