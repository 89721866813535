import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { editIcon } from '../../../utilities/images';
import { getSettings } from '../../actions/settings';

const About = (props) => {

  document.title = "About";

  const [data, setData] = useState('')

  useEffect(()=>{
    getSettings()
  },[])

  const getSettings = () => {

    const params = {
      slug:'about_us'
    }

    props.getSettings(params, resp => {

          if(resp.status == true){
              setData(resp.data.description)   
          }
          else{
              setData(null)
          }
      },
      err => {
          toast.error('Something went wrong');
      }
    )

  }

  return (
    <section className='about'>
      <div className='stickyHead'>
        <div className='row align-items-center'>
          <div className='col-6 col-md-6'>
            <h6 className="horizontal_heading_">About Us</h6>
          </div>
          <div className='col-6 col-md-6 text-end'>
            <Link to='/edit/about_us' className='secondaryBtn'>
              <img src={editIcon} alt='Edit-Icon' className='editImg me-2'/>
              <span>Edit</span>
            </Link>
          </div>
        </div>
      </div>
       <div className='row'>
        <div className='col-md-12 mt-3' >
          <div dangerouslySetInnerHTML={{__html:data}}>
          </div>
        </div>
      </div>
    </section>
    
  )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  getSettings:bindActionCreators(getSettings,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(About);
