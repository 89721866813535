import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { landingLogo, login_img} from '../../../utilities/images';
import { validateOTPVerify, validatePassword } from '../../actions/user';
import LoginNavbar from '../../components/LoginNavbar';

const Otp = (props) => {
    const history = useHistory();
    const [otp, setOtp] = React.useState('')
    const [loading, setLoading] = useState(false);

    //onchange otp
    const handleChangePin = (newValue) => {
        setOtp(newValue)
    }

    //form submit on otp 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!otp) {
            toast.error("Please enter OTP");
            return;
        }
        setLoading(true);
        let params = {
            otp: otp,
        }
        props.validateOTPVerify(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                let userid = res.data;
                //localStorage.setItem("UserId",userid );
                history.push(`/resetPassword/${userid}`);
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error(err.message);
            }
        )
    };


    useEffect(() => {
        document.title = "OTP";
    }, []);

    return (
        <>
            {/* <LoginNavbar /> */}
            <section className='commonLogin'>
                <div className='container-fluid '>
                    <div className='row align-items-center'>
                        <div className="col-lg-12">
                            <a className="navbar-brand d-flex justify-content-center">
                                <img src={landingLogo} className="loginLogo" />
                            </a>
                            <div className="loginForm">
                                <button className='backArrow' onClick={() => {history.push('/forgotPassword')}} ><i className="las la-long-arrow-alt-left"></i></button>
                                <h4 className="heading">Enter OTP</h4>
                                <p className="text">Enter the OTP that we just sent you on your email to <br /> recover your password.</p>
                                <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal">
                                    <div className="verify-box text-center">
                                        <MuiOtpInput length={4} value={otp} onChange={handleChangePin} />
                                    </div>
                                    <p className='createLink mt-3'>Resend</p>
                                    <div className='d-grid gap-2 col-6 mx-auto mt-2'>
                                        <button className=" btn loginBtn m-0" disabled={loading} type="submit">
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    validateOTPVerify: bindActionCreators(validateOTPVerify, dispatch)

});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);