import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import {
    dog_img,
    calander_icon,
    delete_icon,
    user_profile,
    dots_icon,
    user_icon,
    location_icon,
    home_iconDark,
    filter_icon,
    plus,
  } from "../../../utilities/images";
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getBussinessOwnerList } from '../../actions/user/bussinessOwner';
import { updateStatus } from '../../actions/user';

const KennelBussiness = (props) => {

    document.title = "Kennel-Bussiness";

    const [loading, setLoading] = useState(false);
    const [bussiness, setBussiness] = useState([]);
    console.log("bussiness",bussiness);
    const [search, setSearch] = useState(null);
    const [filter, setFilter] = useState(null);
    const [key, setKey] = useState(Math.random());
    const [keyPagination, setKeyPagination] = useState(Math.random());
    const [pageCount , setPageCount] = useState(1);
    const [totalUsers , setTotalUsers] = useState(0);
    const [currentPage , setCurrentPage] = useState(1);

    useEffect(()=>{
        getBussinessOwner();
    },[]);

    const handlePageClick = (e) => {
        setCurrentPage(e.selected+1)
        getBussinessOwner(search,filter,(e.selected+1));
    }

    const onChangeStatus = (value,item) => {

        const params = {
            id:item._id,
            status:value,
            token:props.auth.token
        }  

        swal({
            // title: "Are you sure?",
            text: `Are you sure that you want to change ${item.userInfo?.fullName ?? 'user'} current status to ${value}`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
        .then(resp => { 
            if(resp){
                
                props.updateStatus(params, resp => {
                    if(resp.status){
                        swal("Success", "Status Updated Successfully", "success");
                        getBussinessOwner(search,filter,currentPage);
                    }
                    else{
                        toast.error('Something went wrong');
                        getBussinessOwner(search,filter,currentPage);
                    }
                },
                err => {
                    toast.error('Something went wrong');
                })       
            }        
        });

    }

    const getBussinessOwner = (search=null,filter=null,page=1,limit=10) => {

        const params={
            page:page,
            limit:limit,
            search:search,
            filter:filter,
            token:props.auth.token
        }
        setLoading(true);
        props.getBussinessOwner(params, resp => {
            setLoading(false);
                if(resp.status == true){
                    setBussiness(resp.data.enterprise)
                    setPageCount(resp.data.total_pages)
                    setTotalUsers(resp.data.total)    
                                
                    if(resp.data.total_pages == 1){
                        setKeyPagination(Math.random())
                    }
                }
                else{
                    setBussiness([])
                    toast.error('Something went wrong');
                    setTotalUsers(0)
                }
            },
            err => {
                setLoading(false);
                setBussiness([])
                toast.error('Something went wrong');
            }
        )
    }

    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });

    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
    };

    const handleSearch = (e) => {
        setSearch(e.target.value)
        getBussinessOwner(e.target.value , filter)
    }

    const handleFilter = (type) => {
        setFilter(type)
        getBussinessOwner(search,type)
    }

    return (
        <>
           <section className='stickyHead'>
            <div className="row mb-3 align-items-center">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <h4 className="horizontal_heading_">
                            Kennels Bussiness
                        </h4>
                        <span className="text bussiness">{totalUsers} Total Bussiness</span>
                    </div> 
                    <div className="col-md-3 col-xl-3 col-xxl-3 mb-2 mb-md-0">
                        <div className="input-group">
                            <input className="form-control search__input" placeholder="Search" type="search" value={search || ""} onChange={(e) => handleSearch(e)}/>
                        </div>
                    </div>
                    <div className='col-md-6 col-xl-6 col-xxl-6 text-md-end mb-2 mb-md-0'>
                        <div className='filterPagination justify-content-md-end'>
                            <div className="filter me-3">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={filter_icon} />&nbsp;&nbsp; {filter ?? 'Filter By'}
                                    </button>
                                    <ul className="dropdown-menu" id='filtertabs'>
                                        <li><a className="dropdown-item" onClick={()=> handleFilter(null)}>All</a></li>
                                        <li><a className="dropdown-item" onClick={()=> handleFilter('Active')}>Active</a></li>
                                        <li><a className="dropdown-item" onClick={()=> handleFilter('Inactive')}>Inactive</a></li>
                                        <li><a className="dropdown-item" onClick={()=> handleFilter('Deleted')}>Deleted</a></li>
                                    </ul>
                                </div>
                            </div>
                            <Link to='/kennel-bussiness/addbussiness' className="newTrannerBtn trannerBTN me-3"> 
                                <img src={plus} alt="PlusIcon" className='me-2'/>
                                <span> New Business</span>
                            </Link>
                            <div className='customPagination'>
                                <div className='pagination' key={keyPagination}>
                                    <ReactPaginate
                                        breakLabel=""
                                        nextLabel={<i className='fas fa-caret-right'></i>}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={0}
                                        marginPagesDisplayed={0}
                                        pageCount={pageCount}
                                        previousLabel={<i className='fas fa-caret-left'></i>}
                                        // renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>  
           </section>
            {/* Start KennelBusiness Table */}
            {loading ?
                <div className="col-12 text-center p-5 mt-5 mb-5">
                <span className="spinner-border spinner-border-sm"></span>
                </div>
                :      
                <div className='table-responsive'>
                    <table className='table border-0 kennelTable'>
                        <tbody>
                            {bussiness.length > 0 ? 
                            <>
                                {bussiness.map((item, index)=>{
                                    return(
                                            <tr role='' className='customTableRow' key={index}>
                                                <td>
                                                    <Link to={`/kennel-bussiness/information/${item._id}`} >
                                                        <div className='requestBotCard'>
                                                            <div>
                                                                <div className='requestBotImgCont'>
                                                                    <img src={item.businessInfo.businessPhoto ?? user_profile} className='userImg' alt='UserImg'/>
                                                                    <div className='innerBot'>
                                                                        <h6 className='mb-0'>{item.businessInfo.name}</h6>
                                                                        <span className='botSpan1 border-0'>
                                                                            <img src={location_icon} alt='locationIcon' className='icons_ me-2'/>
                                                                            {item.businessInfo.address}</span> 
                                                                    </div>  
                                                                </div>
                                                                <div className='businessDetails mt-3'>
                                                                    <img src={home_iconDark} alt='HomeIcon' className='me-2' style={{ verticalAlign:'baseline'}}/>
                                                                    <span className='innersubTxt'>No. Of Kennels : <span className='innersubOuter'>{item.businessInfo.kennels}</span></span>
                                                                    <div className='btn-inline mt-2'>
                                                                        {item.businessInfo.specifications.length > 0 ?
                                                                        <>
                                                                            {item.businessInfo.specifications.map((spec, index)=>{
                                                                                return(
                                                                                
                                                                                    <button className='bussinesBtns' key={index}>{spec.title}</button>
                                                                                    
                                                                                )
                                                                            })}
                                                                        </>
                                                                        :null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </Link> 
                                                </td>
                                                <td>
                                                    <div className='requestBotCard'>
                                                        <div>
                                                            <div className='requestBotImgCont'>
                                                                <img src={item.userInfo.profilePhoto ?? user_profile} className='userImg' />
                                                                <div className='innerBot'>
                                                                    <h6 className='mb-0'>{item.userInfo.fullName}</h6>
                                                                    <span className='botSpan1 border-0'>Business Owner</span> 
                                                                </div>  
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </td>
                                                <td>
                                                    <div className='selectContainer dropdown'>
                                                        <div data-bs-toggle='dropdown' aria-expanded='false'
                                                            className={`${item.status == 'Active' ? 'active' : 
                                                            item.status == 'Inactive' ? 'inactive' :
                                                            item.status == 'Deleted' ? 'deleted' :
                                                            'inactive'} dropdown-toggle selectContainerToggle`} >
                                                            <span>
                                                            {item?.status ?? 'Inactive'}
                                                            </span>
                                                        </div>
                                                        <ul className='dropdown-menu status__'>
                                                            <li onClick={() => onChangeStatus('Active',item)} >Active</li>
                                                            <li onClick={() => onChangeStatus('Inactive',item)} >Inactive</li>
                                                            <li onClick={() => onChangeStatus('Delete',item)} >Deleted</li>
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className='dropdown'>
                                                    <img src={dots_icon} className='dropdown-toggle' id='reportDropdown' data-bs-toggle='dropdown' aria-expanded='false' />
                                                    <ul className='dropdown-menu reportMenus' aria-labelledby='reportDropdown'>
                                                        <li className='dropdown-item'>
                                                            <Link to={`/kennel-bussiness/information/${item._id}`} className="dropdownLinks">
                                                                <img src={user_icon}/> &nbsp; View
                                                            </Link>
                                                        </li>
                                                        <li className='dropdown-item'>
                                                            <Link to={`kennel-bussiness/editbussiness/${item._id}`} className="dropdownLinks">
                                                                <img src={user_icon}/> &nbsp; Edit
                                                            </Link>
                                                        </li>
                                                        {/* <li className='dropdown-item'
                                                            > <img src={delete_icon_black}/> &nbsp; Delete
                                                        </li> */}
                                                    </ul>
                                                </td>
                                            </tr>
                                    )
                                })}
                            </>
                            :
                            <>
                            <tr role='' className='customTableRow' >
                                <td colSpan={5}>
                                    No data found
                                </td>
                            </tr>
                            </>}
                        </tbody>
                    </table>
                </div>
            }
            {/* End KennelBusiness Table */}
        </>   
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getBussinessOwner: bindActionCreators(getBussinessOwnerList,dispatch),
    updateStatus:  bindActionCreators(updateStatus, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(KennelBussiness);
