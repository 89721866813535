import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { calander_icon, delete_icon_black, dog_img, plus, user_Img, kannal_occupide } from '../../../utilities/images';
import ReactPaginate from 'react-paginate';
import { getKennelsDetails, deleteKannels } from '../../actions/user/bussinessOwner';
import { Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment-timezone';
import swal from 'sweetalert';
import CustomModal from "../../components/shared/CustomModal";
import AddKennal from '../kennelsBusiness/addkennalModal';


const Kennels = (props) => {

    const { id } = useParams();
    const enterpriseId = id ;
    const [loading, setLoading] = useState(false);
    const [kennels, setKennels] = useState([]);
    const [search, setSearch] = useState(null);
    const [keyPagination, setKeyPagination] = useState(Math.random());
    const [occupiedKennelNo, setOccupiedKennelNo] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: "",
    });


    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: "",
        });
        setKey(Math.random());
        // document.body.setAttribute("style", "overflow:auto")
    };

    useEffect(() => {
        getKennelsDetail();
    }, []);

    const handleSearch = (e) => {
        setSearch(e.target.value)
        getKennelsDetail(e.target.value)
        setKeyPagination(Math.random())

    }

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1)
        getKennelsDetail(search, (e.selected + 1));
    }

    const getKennelsDetail = (search = null, page = 1, limit = 10) => {
        const params = {
            id: enterpriseId,
            page: page,
            limit: limit,
            search: search,
            token: props.auth.token
        }
        setLoading(true);
        props.getKennelsDetails(params, resp => {
            console.log("getKennelsDetails", resp)
            setLoading(false);
            if (resp.status == true) {
                setKennels(resp.data.kennel)
                setPageCount(resp.data.total_pages)
                setTotalUsers(resp.data.total)
                setOccupiedKennelNo(resp.data.occupiedKennelNo)

                if (resp.data.total_pages == 1) {
                    setKeyPagination(Math.random())
                }
            }
            else {
                setKennels([])
                toast.error('Something went wrong');
                setTotalUsers(0)
            }

        },
            err => {
                setLoading(false);
                setKennels([])
                toast.error('Sowmthing went wrong');
            }
        )
    }

    const deleteKannel = (id) => {
        const params = {
            id: id,
            token: props.auth.token
        }
        swal({
            // title: "Are you sure?",
            text: `Are you sure that you want to delete this kannel`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(resp => {
                if (resp) {
                    console.log("params", params)
                    props.deleteKannels(params, resp => {
                        if (resp.status) {
                            swal("Success", "Status Updated Successfully", "success");
                            getKennelsDetail();
                            props.getBussinessDetail(enterpriseId)
                        }
                        else {
                            toast.error('Sowmthing went wrong');
                            getKennelsDetail();
                            props.getBussinessDetail(enterpriseId)
                        }
                    },
                        err => {
                            toast.error('Sowmthing went wrong');
                        })
                }
            });


    }



    return (

        <div className='kennelsDetails'>
            <div className="row mb-3">
                <div className="col-md-3 mb-2 mb-md-0">
                    <h4 className="horizontal_heading_">
                        Kennels
                    </h4>
                    <span className="text bussiness">{occupiedKennelNo}/{totalUsers} Kennels Booked</span>
                </div>
                <div className="col-md-4 col-xl-4 col-xxl-4 mb-2 mb-md-0">
                    <div className="input-group">
                        <input className="form-control search__input" placeholder="Search" type="search" value={search || ""} onChange={(e) => handleSearch(e)} />
                    </div>
                </div>
                <div className='col-md-5 col-xl-5 col-xxl-5 text-md-end mb-2 mb-md-0'>
                    <div className='filterPagination justify-content-md-end'>
                        <button className="newTrannerBtn trannerBTN me-3"
                            onClick={() => {
                                setModalDetail({
                                    show: true,
                                    title: "Add Kennel",
                                    flag: "addkennal",
                                });
                                setKey(Math.random());
                            }}
                        >
                            <img src={plus} alt="PlusIcon" className='me-2' />
                            <span> New Kennel</span>
                        </button>
                        <div className='customPagination'>
                            <div className='pagination' key={keyPagination}>
                                <ReactPaginate
                                    breakLabel=""
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={0}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}

                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start Kennels Table */}
            <div className='table-responsive'>
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <span className="spinner-border spinner-border-sm"></span>
                    </div>
                    :
                    <table className='table border-0 customTable_ kennelsTable_'>
                        <tbody>
                            {kennels?.length > 0 ?
                                <>
                                    {kennels?.map((item, index) => {
                                        return (
                                            <tr role='' className='customTableRow' key={index}>
                                                <td>
                                                    <h6 className='series'>{item.KennelNo}</h6>
                                                </td>
                                                <td>
                                                    <Link to={item.dogId ? `/kennel-bussiness/kennel-detail/${item.enterpriseId}/${item.dogId}` : "#"} >
                                                        <div className='requestBotImgCont'>
                                                            <img src={item.dogPhoto ? item.dogPhoto : kannal_occupide} className="userImg" alt="img" />
                                                            <div className='innerBot'>
                                                                <h6 className='mb-0'>{item.dogName ? item.dogName : ""}</h6>
                                                                <span className='botSpan1'>{item.dogBreed ? item.dogBreed : ""}</span>
                                                                <span className='botSpan'>{item.dogAge ? item.dogGender + + item.dogAge.year + "." + item.dogAge.month + "Y" : ""}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className='requestBotImgCont'>
                                                        {item.booking_detail ?
                                                            <>
                                                                <img src={item.customer_photo ? item.customer_photo : user_Img} className="userImg" alt="img" />

                                                                <div className='innerBot'>
                                                                    <h6 className='mb-0'>{item.customer_name ? item.customer_name : ""}</h6>
                                                                    <span className='botSpan1 border-0'>{item.customer_name ? "Owner" : ""}</span>
                                                                </div>
                                                            </>
                                                            : ""}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="kannelFoot">
                                                        <div className="kannelCalander border-end-0">
                                                            <img src={calander_icon} alt="img" />&nbsp;&nbsp;
                                                            <span>Added on {moment(item.createdAt).format("DD MMMM, YYYY")}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {item.booking_detail ?
                                                        <>
                                                        </>
                                                        :
                                                        <>
                                                            <button className='border-0 bg-transparent' onClick={(e) => deleteKannel(item._id)} >
                                                                <img src={delete_icon_black} alt='DeleteIcon' />
                                                            </button>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </>
                                :
                                <>
                                    <tr role='' className='customTableRow' >
                                        <td colSpan={5}>
                                            No data found
                                        </td>
                                    </tr>

                                </>}
                        </tbody>
                    </table>
                }
            </div>
            {/* End Kennels Table */}
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                title={modalDetail.title}
                ids={modalDetail.flag == "addkennal" ? 'addkennal' : ''}
                child={
                    modalDetail.flag == "addkennal" ? (
                        <AddKennal closeModal={() => handleOnCloseModal()} id={enterpriseId} getKennelsDetail={() => { getKennelsDetail() }} getBussinessDetail={() => { props.getBussinessDetail(enterpriseId) }} />
                    ) :
                        null
                }
                size="lg"
                onCloseModal={() => handleOnCloseModal()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getKennelsDetails: bindActionCreators(getKennelsDetails, dispatch),
    deleteKannels: bindActionCreators(deleteKannels, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Kennels);