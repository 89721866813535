import React from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { closeNav } from '../../../redux/actions/nav';
import {home_icon,home_icon_simple, reservation_icon, reservation_w, booking_icon,booking_w, kennels_icon, tranners_icon,tranners_w, kennels_w, clients_icon,clients_w, staff_icon, staff_w , privacy_icon, privacy_w, terms_icon, terms_w, about_icon, about_w, contact_icon, contact_w } from '../../../utilities/images';
import { connect } from 'react-redux';

const Sidebar = (props) => {
  const history = useHistory();
  const pathName = window.location.pathname;
  const openActions = () => {
    document.body.setAttribute("style", "overflow:hidden");
  }
  const closeActions = () => {
    document.body.setAttribute("style", "overflow:auto");
  }

  const closeSide = () => {
    props.closeNav()
    document.body.setAttribute("style", "overflow:auto");
  }

  return (
    <div className={`vertical-tab ${props.nav.open_nav ? 'slideOpen':''}`} id="vertical-tab">
      <div className="vertical_top" id="vertical_top">
        <a className="closebtn2" onClick={()=>{closeSide();
        closeActions()
        }}><i className="las la-times"></i>  Close</a>
        <span className="expanded_tab">
          <i className="fas fa-arrows-alt-h"></i>
        </span>
      </div>
      <ul className="vertical_menus_">
        <li className="vertical_menu_items_">
          <Link to='/'  onClick={()=>closeSide()} className={pathName == '/' ? "vertical_menus_link_ active" : "vertical_menus_link_"}>
            <img src={home_icon_simple} className="dasd_icons iconShow" alt="HomeIcon" />
            <img src={home_icon} className="dasd_icons iconHide"/>
            <span className="menu_txt">Home</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/clients' onClick={()=>closeSide()}
          className={`${pathName.includes("clients") ? "active" : "" } vertical_menus_link_`}>
            <img src={clients_icon} className="dasd_icons iconShow" alt="Resources" />
            <img src={clients_w} className="dasd_icons iconHide" alt="Resources" />
            <span className="menu_txt">Clients</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/kennel-bussiness' onClick={()=>closeSide()} className={`${pathName.includes("kennel-bussiness") ? "active" : "" } vertical_menus_link_`}>
            <img src={kennels_icon} className="dasd_icons iconShow" alt="Resources" />
            <img src={kennels_w} className="dasd_icons iconHide" alt="Resources" />
            <span className="menu_txt">Kennels Business</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/reportUser' onClick={()=>closeSide()} className={`${pathName.includes("reportUser") ? "active" : "" } vertical_menus_link_`}>
            <img src={kennels_icon} className="dasd_icons iconShow" alt="Resources" />
            <img src={kennels_w} className="dasd_icons iconHide" alt="Resources" />
            <span className="menu_txt">Reported Users</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/privacy' onClick={()=>closeSide()} className={`${pathName.includes("privacy") ? "active" : "" } vertical_menus_link_`}>
            <img src={privacy_icon} className="dasd_icons iconShow" alt="CarePoints" />
             <img src={privacy_w} className="dasd_icons iconHide"/>
            <span className="menu_txt">Privacy Policy</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/terms' onClick={()=>closeSide()} className={`${pathName.includes("terms") ? "active" : "" } vertical_menus_link_`}>
            <img src={terms_icon} className="dasd_icons iconShow" alt="MedList" />
            <img src={terms_w} className="dasd_icons iconHide" alt="MedList" />
            <span className="menu_txt">Terms Of Use</span>
          </Link>
        </li>
        <li className="vertical_menu_items_">
          <Link to='/about' onClick={()=>closeSide()} className={`${pathName.includes("about") ? "active" : "" } vertical_menus_link_`}>
            <img src={about_icon} className="dasd_icons iconShow" alt="Resources" />
            <img src={about_w} className="dasd_icons iconHide" alt="Resources" />
            <span className="menu_txt">About Us</span>
          </Link>
        </li>
      </ul>
      <div className='sidebarFooter'>
        <p className='mb-0'>v1.0 All Rights Reserved</p>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  nav: state.nav
});
const mapDispatchToProps = dispatch => ({
  closeNav: () => dispatch(closeNav()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
