import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams} from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import LoginHeader from './LoginHeader';

const DashboardLayout = (props) => {
    const { id } = useParams();
    const pathName = window.location.pathname;
    const authRoutes = ['/kennel-bussiness/addbussiness',`/kennel-bussiness/editbussiness/${id}`] ;

    return (
        <>
            <div className='wrapper'>
                {/* <LoginHeader/>
                <div className="container d-flex mt-4">
                <Sidebar/>
                </div>
                {props.children} */}
                {authRoutes.includes(pathName) ?
                    <>
                        <LoginHeader />
                        {props.children}
                    </>
                    :
                    <>
                        <LoginHeader />
                        <div className="container d-flex">
                            <Sidebar />
                            <div className='content_areas'>
                                {props.children}
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )

}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);