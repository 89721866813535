import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { calander_icon, mail_icon_fade, plus, user_profile, user_Img } from '../../../utilities/images';
import { getTrainerDetails } from '../../actions/user/bussinessOwner';
import ReactPaginate from 'react-paginate';
import { Link, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import moment from 'moment-timezone';

const Trainers = (props) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [trainers, setTrainers] = useState([]);
    const [search, setSearch] = useState(null);
    const [keyPagination, setKeyPagination] = useState(Math.random());
    const [pageCount, setPageCount] = useState(1);
    const [totalUsers, setTotalUsers] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getTrainerDetail();
    }, [id]);

    const handleSearch = (e) => {
        setSearch(e.target.value)
        getTrainerDetail(e.target.value)
    }

    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1)
        getTrainerDetail(search, (e.selected + 1));
    }


    const getTrainerDetail = (search = null, page = 1, limit = 10) => {
        const params = {
            id: id,
            page: page,
            limit: limit,
            search: search,
            token: props.auth.token
        }
        setLoading(true);
        props.getTrainerDetails(params, resp => {
            setLoading(false);
            if (resp.status == true) {
                setTrainers(resp.data.trainer)
                setPageCount(resp.data.total_pages)
                setTotalUsers(resp.data.total)

                if (resp.data.total_pages == 1) {
                    setKeyPagination(Math.random())
                }
            }
            else {
                setTrainers({})
                toast.error('Something went wrong');
                setTotalUsers(0)
            }

        },
            err => {
                setLoading(false);
                setTrainers([])
                toast.error('Sowmthing went wrong');
            }
        )
    }




    return (
        <div className='trainnerDetails'>
            <div className="row mb-3">
                <div className="col-md-3 mb-2 mb-md-0">
                    <h4 className="horizontal_heading_">
                        Trainers
                    </h4>
                    <span className="text bussiness">{totalUsers} Total Trainers</span>
                </div>
                <div className="col-md-4 col-xl-4 col-xxl-4 mb-2 mb-md-0">
                    <div className="input-group">
                        <input className="form-control search__input" placeholder="Search" type="search" value={search || ""} onChange={(e) => handleSearch(e)} />
                    </div>
                </div>
                <div className='col-md-5 col-xl-5 col-xxl-5 text-md-end mb-2 mb-md-0'>
                    <div className='filterPagination justify-content-md-end'>
                        <div className='customPagination'>
                            <div className='pagination' key={keyPagination}>
                                <ReactPaginate
                                    breakLabel=""
                                    nextLabel={<i className='fas fa-caret-right'></i>}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={0}
                                    marginPagesDisplayed={0}
                                    pageCount={pageCount}

                                    previousLabel={<i className='fas fa-caret-left'></i>}
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start Trainners Table */}
            {loading ?
                <div className="col-12 text-center p-5 mt-5 mb-5">
                    <span className="spinner-border spinner-border-sm"></span>
                </div>
                :
                <table className='table border-0 customTable_ trainnersTable'>
                    <tbody>
                        {trainers.length > 0 ?
                            <>
                                {trainers.map((item, key) => {
                                    return (
                                        <tr role='' className='customTableRow' key={key}>
                                            <td>
                                                <div className='requestBotImgCont'>
                                                    <img src={item.userInfo.profilePhoto ? item.userInfo.profilePhoto : user_Img} className="userImg" alt="img" />
                                                    <div className='innerBot'>
                                                        <h6 className='mb-0'>{item.userInfo.fullName}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="profileEmail">
                                                    <img src={mail_icon_fade} />
                                                    <span className="text">{item.email}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="kannelFoot">
                                                    <div className="kannelCalander border-end-0">
                                                        <img src={calander_icon} alt="img" />&nbsp;&nbsp;
                                                        <span>Added on {moment(item.createdAt).format("DD MMMM, YYYY")} </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                            :
                            <>
                                <tr role='' className='customTableRow' >
                                    <td colSpan={5}>
                                        No data found
                                    </td>
                                </tr>

                            </>}
                    </tbody>
                </table>
            }
            {/* End Trainners Table */}
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getTrainerDetails: bindActionCreators(getTrainerDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Trainers);