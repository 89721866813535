import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import { editIcon } from '../../../utilities/images';
import { getSettings, updateSettings } from '../../actions/settings';
import { CKEditor } from 'ckeditor4-react';

const EditCMS = (props) => {

  const {slug} = useParams();
  const [title, setTitle] = useState('')
  const [data, setData] = useState('')
  const [key, setKey] = useState(Math.random())
  const [editorData, setEditorData] = useState('')

  document.title = `Edit ${slug=='privacy_policy' ? 'Privacy policy' : slug=='term_and_conditions' ? 'Terms of use' : slug=='about_us' ? 'About Us' : 'CMS' }`;

  useEffect(()=>{
    getSettings()
  },[])

  const getSettings = () => {

    const params = {
      slug:slug
    }

    props.getSettings(params, resp => {

        if(resp.status == true){
            setTitle(resp.data.title)
            setData(resp.data.description)   
            setKey(Math.random())
        }
        else{
            setData(null)
        }
      },
      err => {
          toast.error('Something went wrong');
      }  )

  }

  const onChangeEditor = (e) => {
    setEditorData(e.editor.getData())
  }

  const onSubmit = () => {
    const params = {
      token:props.auth.token,
      slug:slug,
      title:title,
      description:editorData
    }

    props.updateSettings(params, resp => {

      if(resp.status == true){
        toast.success('Data updated successfully')
      }

    },
    err => {
        toast.error('Something went wrong');
    })

  }

  return (
    <section className='about'>
       <div className='row align-items-center'>
        <div className='col-6 col-md-6'>
          <h6 className="horizontal_heading_">Edit {slug=='privacy_policy' ? 'Privacy policy' : slug=='term_and_conditions' ? 'Terms of use' : slug=='about_us' ? 'About Us' : 'CMS' }</h6>
        </div>
        <div className='col-6 col-md-6 text-end'>
        </div>
        <div className='col-md-12 mt-3'>
            <CKEditor
              key={key}
              initData={data}
              name='editor'
              readOnly={false}
              onChange={(e) => {      
                onChangeEditor(e)
              }}
              type="basic"
            />
     
        </div>
        <div className='col-md-12 mt-3' >
        <button className='loginBtn mt-2' type='button' onClick={() => onSubmit()} > Submit </button>
        </div>
      </div>
    </section>
    
  )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  getSettings:bindActionCreators(getSettings,dispatch),
  updateSettings:bindActionCreators(updateSettings,dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCMS);
