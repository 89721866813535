if (process.env.NODE_ENV === "production") {

    module.exports = {
  
       AUTH_API_URL: "https://kenneltracker-api.itechnolabs.tech",

    };
   
   } else {
   
    module.exports = {
      
      AUTH_API_URL: "https://kenneltracker-api.itechnolabs.tech",
   
     };
   
   }
   