import React, { useState } from 'react'
import moment from 'moment-timezone';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {user_icon, pen_icon_border,user_full_img, msg_icon,calander_icon_big ,role_icon} from '../../../utilities/images';
const StaffAdd = () => {
    document.title = "Add Staff";
    const [enterprisesDate, setEnterprisesDate] = useState(null);
  return (
    <div className="changePasswordModal">
        <form  className="form_horizontal">
        <div className="cart_max_inner">
            <div className="cart_max_child text-center">
              <div className="cart_img">
                <img src={user_full_img} className="img-fluid" alt="user img" />
                <img src={pen_icon_border} alt="icon" className="profile_edit"/>
              </div>
            </div>
        </div>
            <div className="mb-3 form-group col-md-12">
                <label className="form-label text">Name </label>
                <input
                    className="form-control id_password"
                    placeholder="Teresa Mitchell"
                    name="password"
                    // type={passwordShown ? "text" : "password"}
                    // value={formData.password}
                    // onChange={(e) => { handleChange(e) }}
                />
                <img src={user_icon} className="icon_" alt="password" />
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            <div className="mb-3 form-group col-md-12">
                <label className="form-label text">Email </label>
                <input
                    className="form-control id_password"
                    placeholder="teresamitchell@gmail.com"
                    name="password"
                    // type={passwordShown ? "text" : "password"}
                    // value={formData.password}
                    // onChange={(e) => { handleChange(e) }}
                />
                <img src={msg_icon} className="icon_" alt="password" />
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            <div className="mb-3 form-group col-md-12">
            <label className="form-label text">Role </label>
            <input
                className="form-control id_password"
                placeholder="Select Role"
                name="password"
                // type={passwordShown ? "text" : "password"}
                // value={formData.password}
                // onChange={(e) => { handleChange(e) }}
            />
            <img src={role_icon} className="icon_" alt="password" />
            <span className="toggle_password_ info_icon" 
            // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
            >
                <span 
                // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                    id=""></span>
            </span>
        </div>
            <div className="mb-3 form-group col-md-12">
                <label className="form-label text">Date of Joining </label>
                <DatePicker
                    selected={enterprisesDate}
                    onChange={(date) => setEnterprisesDate(date)}
                    dateFormat="MM/dd/yyyy"
                    minDate={moment().toDate()}
                    placeholderText="MM/DD/YYYY"
                    className="form-control id_password"
                />
                <img src={calander_icon_big} className="icon_" alt="password" />
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            
            <div className='d-grid gap-2 col-7 mx-auto'>
                <button className="loginBtn m-0" type="submit">
                    
                        {/* <span className="spinner-border spinner-border-sm"></span> */}
                    
                    <span>Add</span>
                </button>
            </div>
        </form>
    </div>
  )
}

export default StaffAdd