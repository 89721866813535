import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { calander_icon, delete_icon_black, dog_img, location_icon, location_icon_black, msg_icon, user_Img,kannal_occupide, user_profile } from '../../../utilities/images';
import userProfile from '../userProfile';
import { getInnerKennelsDetails } from '../../actions/user/bussinessOwner';
import { toast } from "react-toastify";
import moment from 'moment-timezone';


const KennelDetailsInner = (props) => {
    const { enterpriseId, dogId } = useParams();
    const history = useHistory();
    const [innerKennels, setInnerKennels] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getInnerKennelsDetail();
    }, []);

    const getInnerKennelsDetail = () => {
        const params = {
            enterpriseId: enterpriseId,
            dogId: dogId,
            token: props.auth.token
        }
        setLoading(true);
        props.getInnerKennelsDetails(params, resp => {
            setLoading(false);
            if (resp.status == true) {
                setInnerKennels(resp.data)
            }
            else {
                setInnerKennels({})
                toast.error('Something went wrong');
            }

        },
            err => {
                setLoading(false);
                setInnerKennels({})
                toast.error('Sowmthing went wrong');
            }
        )
    }

    return (
        <div className='kennelsDetailsInner'>
            <div className="row justify-content-center mb-3">
                <div className="col-md-12">
                    <div className="back_arrow d-flex">
                        <button className="profileBack mb-2" onClick={history.goBack}>
                            <i className="las la-long-arrow-alt-left"></i>Back
                        </button>
                    </div>
                </div>
            </div>
            {loading ?
                <div className="col-12 text-center p-5 mt-5 mb-5">
                    <span className="spinner-border spinner-border-sm"></span>
                </div>
                :
                <div id='cardDetails'>
                    <h6 className='contactSub'>Dog Details</h6>
                    <div className=''>
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                            <h4 className='innersubtext mb-0'>{innerKennels?.kennel?.KennelNo}</h4>
                            {/* <button className='border-0 bg-transparent'>
                        <img src={delete_icon_black} alt='DeleteIcon'/>
                    </button> */}
                        </div>
                        <div className='requestBotImgCont'>
                            <img src={innerKennels?.kennel?.dogPhoto ? innerKennels?.kennel?.dogPhoto : kannal_occupide} className="userlarge" alt="img" />
                            <div className='innerBot'>
                                <h6 className='mb-0'>{innerKennels?.kennel?.dogName}</h6>
                                <span className='botSpan1'>{innerKennels?.kennel?.dogBreed}</span>
                                <span className='botSpan'>{innerKennels?.kennel?.dogGender + " " + innerKennels?.kennel?.dogAge?.year + "." + innerKennels?.kennel?.dogAge?.month + " Y"}</span>
                                <div className="kannelFoot">
                                    <div className="kannelCalander border-end-0">
                                        <img src={calander_icon} className='icon__' alt="img" />&nbsp;&nbsp;
                                        <span>Added on {moment(innerKennels?.kennel?.dogcreatedAt).format("DD MMMM, YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='dogDetailsList mt-2'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="specification">
                                        <p className='mb-1'>Allergies</p>
                                        {innerKennels?.kennel?.allergies.map((item, index) => {
                                            return (
                                                <ul key={index}>
                                                    <li>{item}</li>
                                                </ul>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="specification">
                                        <p className='mb-1'>Sleeping Cycle</p>
                                        <ul>
                                            <li>{innerKennels?.kennel?.sleepingCycle}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="specification">
                                        <p className='mb-1'>Injuries</p>
                                        {innerKennels?.kennel?.injuries.map((item, index) => {
                                            return (
                                                <ul key={index}>
                                                    <li>{item}</li>
                                                </ul>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="specification">
                                        <p className='mb-1'>Favorite Food</p>
                                        {innerKennels?.kennel?.favoriteFood.map((item, index) => {
                                            return (
                                                <ul key={index}>
                                                    <li>{item}</li>
                                                </ul>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='hr' />
                    <div className=''>
                        <div className='requestBotImgCont'>
                            <img src={innerKennels?.kennel?.userPhoto ? innerKennels?.kennel?.userPhoto : user_Img} className="user" alt="img" />
                            <div className='innerBot'>
                                <h6 className='mb-0'>{innerKennels?.kennel?.userName}</h6>
                                <span className='botSpan1 border-0'>{innerKennels?.kennel?.userRole}</span>
                            </div>
                        </div>
                        <ul className='linksDetails mt-4'>
                            <li className='detailsItems'>
                                <img src={msg_icon} alt='mailIcon' />
                                <span className='detailsItemsTxt'>{innerKennels?.kennel?.userEmail}</span>
                            </li>
                            <li className='detailsItems'>
                                <img src={location_icon_black} alt='mapIcon' />
                                <span className='detailsItemsTxt'>{innerKennels?.kennel?.userAddress?.streetAddress}</span>
                            </li>
                        </ul>
                    </div>
                    <hr className='hr' />
                    <div className=''>
                        <h6 className='contactSub'>Dog Schedule</h6>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p className='innersubTxt'>Picked up</p>
                                <ul className='linksDetails mt-3'>
                                    <li className='detailsItems'>
                                        <img src={calander_icon} alt='mailIcon' />
                                        <span className='detailsItemsTxt'>{moment(innerKennels?.kennel?.pickupDate).format("DD MMMM, YYYY")}</span>
                                    </li>
                                    <li className='detailsItems'>
                                        <img src={calander_icon} alt='mapIcon' />
                                        <span className='detailsItemsTxt'>{innerKennels?.kennel?.pickupFrom + " - " + innerKennels?.kennel?.pickupTo}</span>
                                    </li>
                                </ul>
                                <ul className='linksDetails mt-4'>
                                    <li className='detailsItems'>
                                        <img src={location_icon_black} alt='mailIcon' />
                                        <span className='detailsItemsTxt'>{innerKennels?.kennel?.pickupAddress}</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-6'>
                                <p className='innersubTxt'>Dropped off</p>
                                <ul className='linksDetails mt-3'>
                                    <li className='detailsItems'>
                                        <img src={calander_icon} alt='mailIcon' />
                                        <span className='detailsItemsTxt'>{moment(innerKennels?.kennel?.dropoffDate).format("DD MMMM, YYYY")}</span>
                                    </li>
                                    <li className='detailsItems'>
                                        <img src={calander_icon} alt='mapIcon' />
                                        <span className='detailsItemsTxt'>{innerKennels?.kennel?.dropoffFrom + " - " + innerKennels?.kennel?.dropoffTo}</span>
                                    </li>
                                </ul>
                                <ul className='linksDetails mt-4'>
                                    <li className='detailsItems'>
                                        <img src={location_icon_black} alt='mailIcon' />
                                        <span className='detailsItemsTxt'>{innerKennels?.kennel?.dropoffAddress}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getInnerKennelsDetails: bindActionCreators(getInnerKennelsDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(KennelDetailsInner);