import React from 'react'
import {user_icon, pen_icon_border,user_full_img,location_big_icon,remove} from '../../../utilities/images';
const BussinessInformation = () => {
    document.title = "Bussiness Infromation";
  return (
    <div className="changePasswordModal">
        <form  className="form_horizontal">
        <div className="cart_max_inner mb-3">
            <div className="cart_max_child text-center">
              <div className="cart_img">
                <img src={user_full_img} className="img-fluid" alt="user img" />
                <img src={pen_icon_border} alt="icon" className="profile_edit"/>
              </div>
            </div>
        </div>
            
            <div className="mb-3 form-group col-md-12">
                <label className="form-label text">User Name </label>
            <input
                className="form-control id_password"
                placeholder="User Name"
                name="password"
                // type={passwordShown ? "text" : "password"}
                // value={formData.password}
                // onChange={(e) => { handleChange(e) }}
            />
                <img src={user_icon} className="icon_" alt="password" />
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            <div className="mb-3 form-group col-md-12">
                <label className="form-label text">Location </label>
                <textarea className='form-control textArea'>

                </textarea>
                
                <img src={location_big_icon} className="iconLocation_" alt="password" />
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            <div className="mb-4 form-group col-md-12">
                <label className="form-label text">Add Specifications </label>
                <input
                    className="form-control id_password specificationInput"
                    placeholder="Specifications"
                    name="password"
                    // type={passwordShown ? "text" : "password"}
                    // value={formData.password}
                    // onChange={(e) => { handleChange(e) }}
                />
                {/* <img src={user_icon} className="icon_" alt="password" /> */}
                <button className="addBtn_">Add</button>
                <span className="toggle_password_ info_icon" 
                // onClick={() => { togglePasswordVisiblity(!passwordShown) }}
                >
                    <span 
                    // className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"}
                        id=""></span>
                </span>
            </div>
            <div className="mb-4 form-group col-md-12">
                <div className="specification mb-2">
                    <p>Specifications</p>
                    <ul>
                        <li><img src={remove}/> &nbsp; 100+ Kennels</li>
                        <li><img src={remove}/> &nbsp; Good Staff</li>
                    </ul>
                </div>
            </div>
            <div className='d-grid gap-2 col-8 mx-auto'>
                <button className="loginBtn m-0" type="submit">
                    
                        {/* <span className="spinner-border spinner-border-sm"></span> */}
                    
                    <span>Save Changes</span>
                </button>
            </div>
        </form>
    </div>
  )
}

export default BussinessInformation