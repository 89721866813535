import React, { useEffect, useState } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from "react-router-dom";
import {
  dots_icon,
  request_img,
  dog_img,
  calander_icon,
  user_icon,
  delete_icon_black,
} from "../../../utilities/images";
import { getClientDetails, updateStatus } from "../../actions/user";
import { toast } from "react-toastify";
import swal from 'sweetalert';
import moment from 'moment';

const ClientInformation = (props) => {

  document.title = "Client Information";
  const { id } = useParams();
  const history = useHistory();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getClientDetail(id);
  }, [id]);

  const getClientDetail = (id) => {

    const params = {
      id: id,
      token: props.auth.token
    }
    setLoading(true);
    props.getClientDetails(params, resp => {
      setLoading(false);
      if (resp.status == true) {
        setData(resp.data)
      }
      else {
        setData([])
        toast.error('Sowmthing went wrong');
      }
    },
      err => {
        setLoading(false);
        setData({})
        toast.error('Sowmthing went wrong');
      }
    )
  }

  const onChangeStatus = (val) => {

    const params = {
      id: id,
      status: val,
      token: props.auth.token
    }

    swal({
      // title: "Are you sure?",
      text: `Are you sure that you want to change ${data?.customer?.userInfo?.fullName ?? 'user'} current status to ${val}`,
      // icon: "warning",
      dangerMode: false,
      buttons: true,
    })
      .then(resp => {
        if (resp) {

          console.log(params);

          props.updateStatus(params, resp => {
            if (resp.status) {
              swal("Success", "Status Updated Successfully", "success");
              getClientDetail(id);
              history.push("/clients")
            }
            else {
              toast.error('Sowmthing went wrong');
              getClientDetail(id)
            }
          },
            err => {
              toast.error('Sowmthing went wrong');
            })
        }
      });

  }




  return (
    <>
      <section className="information__">
        <div className="row justify-content-center mb-2">
          <div className="col-md-12">
            <div className="back_arrow d-flex">
              <Link to="/clients">
                <button
                  className="profileBack mb-2"
                >
                  <i className="las la-long-arrow-alt-left"></i> Back
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          {loading ?
            <div className="col-12 text-center p-5 mt-5 mb-5">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
            :
            <div className="col-md-12">
              <div className="card" id="dogDetails">
                <div className="card-body">
                  <div className="userProfile border-0">
                    <div className="dogDetailsHeading">
                      <h6 className="profileHeading_">Client Information</h6>
                    </div>
                    <div className="dropdown">
                      <img src={dots_icon} className="dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                      <ul className="dropdown-menu reportMenus" aria-labelledby="dropdownMenuButton1">
                        <li onClick={() => onChangeStatus('Active')} className='dropdown-item'>
                          <Link to="#" className="dropdownLinks">
                            <img src={user_icon} /> &nbsp; Active
                          </Link>
                        </li>
                        <li onClick={() => onChangeStatus('Inactive')} className='dropdown-item'>
                          <Link to="#" className="dropdownLinks">
                            <img src={user_icon} /> &nbsp; Inactive
                          </Link>
                        </li>
                        <li onClick={() => onChangeStatus('Delete')} className='dropdown-item'>
                          <Link to="#" className="dropdownLinks">
                            <img src={delete_icon_black} alt='delete Icon' /> &nbsp; Delete
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="usecalss">
                    <div className="dogDetailsFoot">
                      <div className="requestCard mt-3">
                        <div className="requestTopCard">
                          <div className="requestCardImg">
                            <img src={data.customer?.userInfo?.profilePhoto ?? request_img} className="userImg" alt="img" />
                          </div>
                          <div className="requestCardContent">
                            <h6>{data.customer?.userInfo?.fullName}</h6>
                            <span className="text bussiness">{data.customer?.email} -- Client</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="dogDetailsHeading mt-3">
                    <h6 className="profileHeading_">{data.dogs?.length > 0 ? data.dogs?.length : ""} Dogs Information</h6>
                  </div>
                  {data.dogs?.length > 0 ?
                    <>
                      {data.dogs.map((item, key) => {
                        return (
                          <div className="kennelCard mb-2 mt-3" key={key}>
                            <div className="kennelHeading">
                              <div className="series">
                                {" "}
                                <h6>#{key + 1}</h6>
                              </div>
                              <div className="requestBotCard">
                                <div>
                                  <div className="requestBotImgCont">
                                    <img src={item.profilePhoto && item.profilePhoto != '' ? item.profilePhoto : dog_img} className="userImg" alt="img" />
                                    <div className="innerBot">
                                      <h6 className="mb-0">{item.name}</h6>

                                      <span className="botSpan1">{item.breed}</span>
                                      <span className="botSpan">{item.gender} {item.age?.year}.{item.age?.month} Y</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="kannelFoot">
                              <div className="kannelCalander">
                                <img src={calander_icon} />
                                &nbsp;&nbsp; <span>Added on {moment(item.createdAt).format('DD MMM, YYYY')}</span>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                    :
                    <>
                      <tr className='customTableRow'  >
                        <td colSpan={5}>
                          No dogs added  
                        </td>
                      </tr>

                    </>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  getClientDetails: bindActionCreators(getClientDetails, dispatch),
  updateStatus: bindActionCreators(updateStatus, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientInformation);
