import React from 'react'
import {msg_icon,user_icon,send_icon } from '../../../utilities/images';
const Contact = () => {
  return (
    <section className='about'>
    <div className="container">
    <div className='row'>
      <div className='col-12'>
      <div className='content_areas'>
          <h6 className="horizontal_heading_ mb-3">Contact Us</h6>
          <div className='contactForm'>
            <h4 className="contactSub">Let us know what you want?</h4>
                <form className="form_horizontal">
                    <div className='row'>
                    <div className="mb-4 form-group col-md-6">
                        <label className="form-label">Name </label>
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder='Name'
                            // value={formData.email}
                            // onChange={(e) => { handleChange(e) }}
                        />
                        <img src={user_icon} className="icon_" alt="message" />
                    </div>
                    <div className="mb-4 form-group col-md-6">
                        <label className="form-label">Email </label>
                        <input
                            className="form-control id_password"
                            placeholder="Password"
                            name="email"
                            // type={passwordShown ? "text" : "password"}
                            // value={formData.password}
                            // onChange={(e) => { handleChange(e) }}
                        />
                        <img src={msg_icon} className="icon_" alt="password" />
                    </div> 
                    <div className="mb-4 form-group col-md-12">
                        <label className="form-label">Message </label>
                        <textarea className='form-control' rows="5"  placeholder="Write...">

                        </textarea>
                    </div> 
                    <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                        <button className="loginBtn m-0"  type="submit">
                            <span><img src={send_icon}/> &nbsp; Send</span>
                        </button>
                    </div>
                    </div>
                </form>
            </div>
       </div>
      </div>
    </div>
  </div>
  </section>
  )
}

export default Contact